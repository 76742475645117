<template>
  <b-overlay
    :show="isLoading"
  >
    <template #overlay>
      <div class="d-flex align-items-center justify-content-center mt-1">
        <b-spinner
          type="border"
          variant="primary"
        />
      </div>
      <div class="d-flex justify-content-center">
        <p class="pt-1">
          {{ $t('loading') }}
        </p>
      </div>
    </template>
    <b-row v-if="!isLoading">
      <!-- condiciones Contractual -->
      <b-col
        v-if="!isEdit"
        cols="12"
      >
        <specific-details
          :conditions="member.contractual_condition"
          :option="member.member_type.key"
          :section-coaching-staff="sectionCoachingStaff"
          :check-permission="checkPermission"
          :show-edit-button="true"
          @edit-conditions="handleEditConditions"
        />
      </b-col>
      <!--/ Contractual -->

      <b-col
        v-if="isEdit"
        md="12"
      >
        <validation-observer
          ref="formEditContractualConditions"
        >
          <!-- :conditions="member.conditions" -->
          <contractual-conditions
            :member="member"
            :countries="countries"
            :option="member.member_type.key"
            :color-primary-btn="colorPrimaryBtn"
            :section-coaching-staff="sectionCoachingStaff"
          />
        </validation-observer>
      </b-col>

      <!-- historico de condiciones -->
      <b-col
        v-if="!isEdit"
        cols="12"
        class="mt-1"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.4)'"
          v-b-toggle.collapse-record
          pill
          block
          variant="outline-primary"
        >
          {{ $t('generic.historicalData') }}
        </b-button>
        <b-collapse
          id="collapse-record"
          class="mt-2"
        >
          <b-row
            v-if="checkPermission(['REGISTER_MEMBERS_CONTRACTUAL_CONDITIONS', 'ALLOW_ALL'])"
          >
            <b-col cols="12 mb-1">
              <span class="float-right">
                {{ $t('add-conditions') }}
                <b-button
                  class="ml-1 btn-icon rounded-circle"
                  variant="primary"
                  @click="handleNewConditions"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-table
                :items="contractualConditionsListLog.data"
                :fields="fields"
                striped
                responsive
              >

                <template #row-details="row">
                  <b-card>
                    <b-row class="mb-2">
                      <b-col cols="12">
                        <specific-details
                          :conditions="row.item"
                          :check-permission="checkPermission"
                          :show-edit-button="false"
                          @edit-conditions="handleEditConditions"
                        />
                      </b-col>
                    </b-row>
                  </b-card>
                </template>

                <template #cell(type_contractual_conditions)="data">
                  <span v-if="hasKeyObject(data.item, 'type_contractual_conditions') && data.item.type_contractual_conditions == 'TRANSFER'">
                    {{ $t('transfer') }}
                  </span>
                  <span v-else>
                    {{ $t('free-of-contract') }}
                  </span>
                </template>

                <template #cell(ceding_club)="data">
                  <span v-if="data.item.transfer">{{ data.item.transfer.ceding_club }} </span>
                </template>

                <template #cell(contract_agreement)="data">
                  <span v-if="hasKeyObject(data.item.transfer, 'contract_agreement') && data.item.transfer.contract_agreement == 'YES'">
                    {{ $t('generic.yes') }}
                  </span>
                  <span v-else>
                    {{ $t('generic.not') }}
                  </span>
                </template>

                <template #cell(type_contract)="data">
                  <span v-if="data.item.type_contract">{{ data.item.type_contract.name }} </span>
                </template>

                <!-- <template #cell(updated_at)="data">
                  <span v-if="data.item.updated_at">{{ data.item.updated_at | formatDate }} </span>
                </template> -->
                <template #cell(created_at)="data">
                  <span v-if="data.item.created_at">{{ data.item.created_at | formatDate }} </span>
                </template>

                <template #cell(show_details)="row">
                  <b-form-checkbox
                    v-model="row.detailsShowing"
                    class="i-check-hide-co"
                    @change="row.toggleDetails"
                  >
                    <span
                      class="i-text-details-so"
                      :class="{'text-primary' : row.detailsShowing }"
                    >
                      {{ $t('buttons.details') }}
                      <feather-icon
                        icon="ArrowDownIcon"
                        :class="row.detailsShowing ? 'i-icon-arrow-down' : 'i-icon-arrow-up'"
                      />
                    </span>
                  </b-form-checkbox>
                </template>
              </b-table>
              <app-paginator
                :data-list="contractualConditionsListLog"
                @pagination-data="fetchDataLog"
              />
            </b-col>
          </b-row>
        </b-collapse>
      </b-col>
      <!--/ historico de condiciones -->
      <b-col
        v-if="isEdit"
        cols="12"
        class="mt-1"
      >
        <div class="float-right">

          <!-- Buttons cancel -->
          <b-button
            variant="secondary"
            pill
            style="margin-left: 17px;"
            @click="getMember"
          >
            {{ $t('buttons.cancel') }}
          </b-button>

          <b-button
            variant="primary"
            pill
            style="margin-left: 17px;"
            @click="handleSaveConditions"
          >
            <!-- <span></span> -->
            {{ isNew ? $t("buttons.save") : $t('buttons.update') }}
            <!-- {{ $t("buttons.save") }} -->
          </b-button>

        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col
        md="12"
        class="i-height-div"
      />
    </b-row>
  </b-overlay>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { isObject, hasKeyObject } from '@core/utils/utils'
import ContractualConditions from '@/views/member/contractual-conditions/ContractualConditions.vue'
import AppPaginator from '@/views/components/app-paginator/AppPaginator.vue'
import SpecificDetails from './SpecificDetails.vue'

export default {
  components: {
    // ValidationProvider,
    ValidationObserver,
    SpecificDetails,
    ContractualConditions,
    AppPaginator,
  },
  directives: {
    Ripple,
  },
  props: {
    checkPermission: {
      type: Function,
      default: null,
    },
    memberId: {
      type: [Number, String],
      default: null,
    },
    countries: {
      type: Array,
      default: null,
    },
    cities: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      checkDeleted: true,
      isLoading: true,
      isEdit: false,
      isNew: false,
      sectionCoachingStaff: 'COACHING_STAFF',
      err: null,
      tempQuery: {
        filtersObj: {},
        paramsObj: {
          page: 1,
          perPage: 15,
          // included: 'team,club,category,memberType,playerStatus',
        },
      },
      fields: [{
        key: 'type_contractual_conditions',
        label: this.$t('origin'),
        sortable: true,
      }, {
        key: 'ceding_club',
        label: this.$t('club-cedente'),
        sortable: true,
      }, {
        key: 'contract_agreement',
        label: this.$t('agreement-or-contract-title'),
        sortable: true,
      }, {
        key: 'type_contract',
        label: this.$t('type-contract'),
        sortable: true,
      }, {
        key: 'created_at',
        label: this.$t('created-at'),
        sortable: true,
      }, {
        key: 'show_details',
        label: '',
        sortable: false,
      }],
    }
  },
  computed: {
    ...mapGetters({
      member: 'memberStore/member',
      contractualConditionsListLog: 'contractualConditions/listLog',
      contractual_conditions: 'contractualConditions/contractual_conditions',
      colorPrimaryBtn: 'colorPrimaryBtn',
    }),
  },
  watch: {
    '$refs.formEditContractualConditions': function formEditContractualConditions(first) {
    },
  },
  mounted() {
    if (this.memberId == null) {
      this.$router.push({ name: 'member' })
    }
    this.getMember()
    // this.fetchData()
    this.tempQuery.filtersObj.member_hash = this.memberId
    this.fetchDataLog()

    // setTimeout(() => {
    //   this.isLoading = false
    // }, 3000)
  },

  methods: {
    isObject,
    hasKeyObject,
    ...mapActions({
      updateMember: 'memberStore/update',
      fetchMemberById: 'memberStore/fetchMemberById',
      fetchDataContractualConditionLogs: 'contractualConditions/fetchDataLogByMemberId',
      store: 'contractualConditions/store',
      // Quir
      // membersWhitIncompleteData: 'memberStore/membersWhitIncompleteData',
    }),
    async fetchDataLog(pagination) {
      if (pagination) {
        this.tempQuery.paramsObj.page = pagination.page
        this.tempQuery.paramsObj.perPage = pagination.perPage
      }
      await this.fetchDataContractualConditionLogs(this.tempQuery)
        .then(() => {
        })
        .catch(error => {
          this.responseCatch(error)
        })
    },
    // TODO: test
    handleEditConditions(conditionsData) {
      Object.assign(this.member.contractual_condition, conditionsData)
      this.isEdit = true
      this.isNew = false
      this.returnTop()
    },
    async getMember() {
      await this.getContractualConditionByMemberId(this.memberId)
      this.isEdit = false
      this.isNew = false
    },
    handleSaveConditions() {
      if (this.isNew) {
        this.storeContractualCondition()
      } else {
        this.onUpdateConditions()
      }
    },
    async onUpdateConditions() {
      this.isLoading = true
      try {
        this.err = this.$refs.formEditContractualConditions.errors
        this.$refs.formEditContractualConditions.validate().then(async isValid => {
          if (!isValid && this.member.is_complete_data === true) {
            this.showError(this.$t('fields-are-missing-to-complete'))
            this.isLoading = false
            return
          }
          await this.updateMember(this.member).then(() => {
            this.getMember()
            this.isLoading = false
          }).catch(error => {
            this.isLoading = false
            this.responseCatch(error)
          })
        }).catch(error => {
          this.isLoading = false
          this.responseCatch(error)
        })
      } catch (error) {
        this.isLoading = false
        // this.responseCatch(error)
        console.error(error)
        this.showError(error)
      }
    },
    async storeContractualCondition() {
      await this.store(this.member.contractual_condition).then(() => {
        this.getMember()
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    // Test
    handleNewConditions() {
      Object.assign(this.member.contractual_condition, this.contractual_conditions)
      this.isEdit = true
      this.isNew = true
      this.member.contractual_condition.member_hash = this.memberId
      this.returnTop()
    },
    returnTop() {
      const rootEle = document.documentElement
      rootEle.scrollTo({
        top: 50,
        behavior: 'smooth',
      })
    },
    async getContractualConditionByMemberId() {
      await this.fetchMemberById(this.memberId).then(() => {
        // const { data } = response.data
        // this.contractual_conditions
        this.isLoading = false
      }).catch(error => {
        this.responseCatch(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style scoped>
.i-text-details-so {
  font-size: 12px;
  color: #33334d;
  visibility: visible;
  cursor: pointer;
}

.i-text-details-so:hover {
  color: #6abeff;
}

.i-min-height-register-so {
  min-height:300px;
}

.i-check-hide-co {
  visibility: hidden;
}

.i-icon-arrow-up {
  padding-bottom: 2px;
}

.i-icon-arrow-down {
  padding-top: 2px;
  transform: rotate(180deg);
}
</style>
