<template>
  <div>
    <b-list-group-item>
      <b-row>
        <b-col md="6">
          <strong class="float-left">{{ $t('notification-address') }}</strong>
        </b-col>
        <b-col md="6">
          <span
            class="float-left"
          />
        </b-col>
      </b-row>
    </b-list-group-item>

    <!-- Address -->
    <b-list-group-item>
      <b-row>
        <b-col md="6">
          <strong class="float-left">{{ $t('address') }}</strong>
        </b-col>
        <b-col md="6">
          <span
            v-if="address.address"
            class="float-left"
          >{{ address.address }}</span>
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item>
      <b-row>
        <b-col md="6">
          <strong class="float-left">{{ $t('postal-code') }}</strong>
        </b-col>
        <b-col md="6">
          <span
            v-if="address.zip_code != null"
            class="float-left"
          >
            {{ address.zip_code }}
          </span>
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item>
      <b-row>
        <b-col md="6">
          <strong class="float-left">{{ $t('generic.country') }}</strong>
        </b-col>
        <b-col md="6">
          <span
            v-if="address.country != null"
            class="float-left"
          >
            {{ address.country.name }}
          </span>
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item>
      <b-row>
        <b-col md="6">
          <strong class="float-left">{{ $t('generic.state') }}</strong>
        </b-col>
        <b-col md="6">
          <span
            v-if="address.state != null"
            class="float-left"
          >
            {{ address.state.name }}
          </span>
        </b-col>
      </b-row>
    </b-list-group-item>

  </div>
</template>
<script>
export default {
  props: {
    address: {
      type: Object,
      default: null,
    },
  },
}
</script>
<style lang="">

</style>
