<template>
  <div>
    <span>
      <validation-observer ref="form1Update">
        <b-form>
          <head-title-wizard
            :section-player="sectionPlayer"
            :section-coaching-staff="sectionCoachingStaff"
            :option="option"
            :gender="genderSelect ? genderSelect : '' "
          />
          <b-row>
            <b-col
              sm="12"
              md="12"
              lg="12"
            >
              <b-row align-h="center">
                <b-col>
                  <div class="text-center">
                    <upload-image
                      :tooltip-text="'generic.allowedFormat'"
                      :button-text="'generic.uploadPhoto'"
                      :avatar-icon="'icon-0-icons-dark-avatar'"
                      :image-size="'10rem'"
                      :apply-b-link="true"
                      :disabled="member.club_hash == null"
                      :path-file-avatar="member.avatar_file ? member.avatar_file.url_thumb : ''"
                      @intance-file-x="fileValidation($event, 'avatar_file')"
                    />
                    <br>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <!-- <template v-if="checkPermission(['ALLOW_ALL'])"> -->
            <b-col
              v-show="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
              sm="12"
              md="6"
              lg="6"
            >
              <b-form-group>
                <label for="club-country-id">{{ $t('generic.country') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="country"
                >
                  <v-select
                    id="club-country-id"
                    v-model="member.club.country_hash"
                    label="name"
                    :options="countries"
                    :reduce="(country) => country.id"
                    :placeholder="$t('generic.selectCountry')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <div slot="no-options">
                      {{ $t('generic.noRecordsFound') }}
                    </div>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-show="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
              sm="12"
              md="6"
              lg="6"
            >
              <b-form-group>
                <label for="club-state-id">{{ $t('generic.state') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="state"
                >
                  <v-select
                    id="club-state-id"
                    v-model="member.club.state_hash"
                    label="name"
                    :options="clubStates"
                    :reduce="city => city.hash"
                    :disabled="member.club.country_hash == null"
                    :placeholder="$t('generic.selectState')"
                  >
                    <div slot="no-options">
                      {{ $t('generic.noRecordsFound') }}
                    </div>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- </template> -->
            <b-col
              v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
              sm="12"
              md="6"
              lg="6"
            >
              <b-form-group>
                <label for="club-id">{{ $t('generic.club') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="sportsClubName"
                >
                  <v-select
                    id="club-id"
                    v-model="member.club_hash"
                    label="club_name"
                    :options="clubs"
                    :reduce="club => club.hash"
                    :disabled="member.club.state_hash == null"
                    :placeholder="$t('corporate.selectSportClub')"
                  >
                    <div slot="no-options">
                      {{ $t('generic.noRecordsFound') }}
                    </div>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- team -->
            <b-col
              sm="12"
              md="6"
              lg="6"
            >
              <b-form-group>
                <label>{{ $t('generic.team') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="team"
                >
                  <v-select
                    v-model="member.team_hash"
                    label="name"
                    :placeholder="$t('generic.selectTeam')"
                    :options="teams"
                    :reduce="item => item.hash"
                    :disabled="member.club_hash == null"
                  >
                    <div slot="no-options">
                      {{ $t('generic.noRecordsFound') }}
                    </div>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- end Team -->

            <!-- Category -->
            <template
              v-if="option === sectionPlayer"
            >
              <b-col
                sm="12"
                md="6"
                lg="6"
              >
                <b-form-group>
                  <label>{{ $t('member.form.label.category') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="category"
                  >
                    <v-select
                      v-model="member.category_hash"
                      label="name"
                      :placeholder="$t('member.form.placeholder.selectCategory')"
                      :options="categories"
                      :reduce="item => item.id"
                    >
                      <div slot="no-options">
                        {{ $t('generic.noRecordsFound') }}
                      </div>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
                sm="12"
                md="6"
                lg="6"
              />
            </template>
            <b-col
              sm="12"
              md="6"
              lg="6"
            >
              <b><span>{{ $t('generic.contractTerm') }}</span></b>
              <!-- <hr> -->
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="6"
            />
            <b-col
              sm="12"
              md="3"
              lg="3"
            >
              <b-form-group>
                <label>{{ $t('generic.initDate') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="initDate"
                >
                  <b-form-datepicker
                    v-model="member.validity_init_date"
                    :locale="$i18n.locale"
                    class="form-control"
                    :placeholder="$t('member.form.placeholder.selectDate')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="3"
              lg="3"
            >
              <!-- <div v-if="member.contractIsIndefinite == 0"> -->
              <b-form-group>
                <label>{{ $t('generic.endDate') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="endDate"
                >
                  <b-form-datepicker
                    v-model="member.validity_end_date"
                    :locale="$i18n.locale"
                    :min="member.validity_init_date"
                    class="form-control"
                    :placeholder="$t('member.form.placeholder.selectDate')"
                    :state="errors.length > 0 ? false : null"
                    :disabled="member.validity_init_date == null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- </div> -->
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="6"
            >
              <upload-file
                :title="$t('member.form.label.contract')"
                folder="members"
                :disabled="member.club_hash == null"
                :club-id="member.club_hash"
                :file-title="member.file_contract ? member.file_contract.title : ''"
                @uploaded-file="assignFileContract"
              />
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="6"
            >
              <b-form-group>
                <label>{{ $t('member.form.label.registrationFederation') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="registrationFederation"
                  rules="required|max:254|alphaNumSpacesRegex"
                >
                  <b-form-input
                    v-model="member.registration_in_federation"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('member.form.placeholder.registryNumber')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <template v-if="option == sectionPlayer">
              <b-col
                sm="12"
                md="6"
                lg="6"
              >
                <b-form-group>
                  <label>{{ $t('member.form.label.idFifa') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    name="idFifa"
                    rules="integer|max:50"
                  >
                    <b-form-input
                      v-model="member.id_fifa"
                      type="text"
                      :placeholder="$t('member.form.placeholder.enterIdFifa')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>
            <b-col
              sm="12"
              md="6"
              lg="6"
            >
              <b-form-group>
                <label>{{ $t('member.form.label.dateAdmission') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="dateAdmission"
                  rules="required"
                >
                  <b-form-datepicker
                    v-model="member.date_of_admission"
                    :locale="$i18n.locale"
                    :min="member.validity_init_date"
                    :max="member.validity_end_date"
                    class="form-control"
                    :placeholder="$t('member.form.placeholder.selectDate')"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Solo para jugador -->
            <template v-if="option == sectionPlayer">

              <b-col
                sm="6"
                md="3"
                lg="3"
              >
                <jersey-number-modal
                  :jersey-numbers="jerseyNumbers"
                  :jersey-number.sync="member.jersey_number"
                  :color-primary-btn="colorPrimaryBtn"
                  @handle-jersey-by-team-id="handleJerseyByTeamId"
                />
              </b-col>
              <div class="w-100" />
              <b-col
                sm="6"
                md="3"
                lg="3"
              >
                <b-form-group>
                  <label>{{ $t('member.form.label.status') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="status"
                  >
                    <v-select
                      v-model="member.player_status"
                      label="name"
                      :placeholder="$t('member.form.placeholder.selectStatus')"
                      :options="playerStatuses"
                      :state="errors.length > 0 ? false : null"
                      :reduce="item => item"
                    >
                      <div slot="no-options">
                        {{ $t('generic.noRecordsFound') }}
                      </div>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-form-group>
              </b-col>
              <b-col
                v-if="memberPlayerStatusKey == 'LOANED_BY'"
                cols="12"
                sm="12"
                md="3"
                lg="3"
              >
                <b-form-group>
                  <label>{{ $t('club-cedente') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="sportsClubName"
                    rules="required|max:254|alphaNumSpacesRegex"
                  >
                    <b-form-input
                      v-model="member.ceding_club"
                      type="text"
                      :placeholder="$t('enter-sport-club-name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="memberPlayerStatusKey == 'LOANED_TO'"
                cols="12"
                sm="12"
                md="3"
                lg="3"
              >
                <b-form-group>
                  <label>{{ $t('transferee-club') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="sportsClubName"
                    rules="required|max:254|alphaNumSpacesRegex"
                  >
                    <b-form-input
                      v-model="member.ceding_club"
                      type="text"
                      :placeholder="$t('enter-sport-club-name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>

            <!-- End status y jersey number -->

            <!-- Cuerpo técnico -->
            <template v-if="option == sectionCoachingStaff">
              <b-col
                sm="12"
                md="6"
                lg="6"
              >
                <b-form-group>
                  <label>{{ $t('member.form.label.gender') }} <span
                    class="text-danger"
                  >*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="gender"
                  >
                    <v-select
                      v-model="member.gender_hash"
                      label="name"
                      :placeholder="$t('member.form.placeholder.selectGender')"
                      :options="genders"
                      :reduce="item => item.id"
                      :state="errors.length > 0 ? false : null"
                    >
                      <div slot="no-options">
                        {{ $t('generic.noRecordsFound') }}
                      </div>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-form-group>
              </b-col>
              <b-col
                sm="10"
                md="4"
                lg="4"
              >
                <b-form-group>
                  <label for="name">
                    {{ $t("generic.jobPosition") }} <span
                      v-if="!applyOther"
                      class="text-danger"
                    >*</span>
                  </label>
                  <validation-provider
                    #default="{ errors }"
                    :rules="applyOther ? '' : 'required'"
                    name="jobPosition"
                  >
                    <v-select
                      v-model="member.staff_job_position_hash"
                      label="name"
                      :options="staffJobPositions"
                      :reduce="job => job.id"
                      :disabled="applyOther || member.gender_hash == null"
                      :placeholder="$t('generic.jobPosition')"
                    >
                      <div slot="no-options">
                        {{ $t('generic.noRecordsFound') }}
                      </div>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="2"
                md="2"
                lg="2"
              >
                <b-button
                  class="mt-2"
                  :variant="applyOther ? 'secondary' : 'primary'"
                  pill
                  @click="applyOther = !applyOther"
                >
                  <span v-if="applyOther"> {{ $t('buttons.cancel') }} </span>
                  <span v-else>{{ $t('generic.addJobPosition') }}</span>
                </b-button>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
              />
              <b-col
                v-if="applyOther"
                sm="10"
                md="4"
                lg="4"
              >
                <b-form-group>
                  <label for="name">
                    {{ $t("generic.jobPosition") }} <span class="text-danger">*</span>
                  </label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required|max:254|alphaNumSpacesRegex"
                    name="jobPosition"
                  >
                    <b-form-input
                      v-model="member.another_job"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('generic.enterJob')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>

          </b-row>
        </b-form>
      </validation-observer>
      <div class="btn-footer-modal">

        <!-- Buttons cancel -->
        <b-button
          variant="secondary"
          pill
          style="margin-left: 17px;"
          @click="$emit('hidden-form')"
        >
          {{ $t('buttons.cancel') }}
        </b-button>

        <!-- button save -->
        <overlay-button :loading="isLoadingSave">
          <b-button
            variant="primary"
            pill
            style="margin-left: 17px;"
            :style="colorPrimaryBtn"
            @click="onUpdateMember"
          >
            {{ $t("buttons.save") }}
          </b-button>
        </overlay-button>

      </div>
      <div class="float-right">
        <b-button
          v-if="checkDeleted"
          variant="outline-secondary"
          pill
          style="margin-left: 17px;"
          class="d-none d-sm-none d-md-block"
          @click="onRemoveMember"
        >
          <i class="icon-0-icons-dark-trash-can mr-25" />
          <span class="align-middle">{{ $t('buttons.delete') }}</span>
        </b-button>
      </div>
      <b-button
        v-if="checkDeleted"
        class="d-block d-sm-block d-md-none w-100 mt-2"
        variant="outline-secondary"
        pill
        @click="onRemoveMember"
      >
        <i class="icon-0-icons-dark-trash-can mr-25" />
        <span class="align-middle">{{ $t('buttons.delete') }}</span>
      </b-button>
    </span></div>
</template>
<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import UploadFile from '@/views/member/components/UploadFile.vue'
import OverlayButton from '@/views/partials/OverlayButton.vue'
import checkPermission from '@/auth/permissions'
import { validatorFileSize, validatorFileType } from '@core/utils/validations/validators'
import { mapActions } from 'vuex'
import { required } from '@validations'
import { hasKeyObject, isObject } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import UploadImage from '../partials/UploadImageAvatar.vue'
import JerseyNumberModal from './JerseyNumberModal.vue'
import HeadTitleWizard from './components/HeadTitleWizard.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    UploadImage,
    JerseyNumberModal,
    UploadFile,
    OverlayButton,
    HeadTitleWizard,
  },
  directives: {
    Ripple,
  },
  props: {
    member: {
      type: Object,
      default: null,
    },
    clubs: {
      type: Array,
      default: null,
    },
    countries: {
      type: Array,
      default: null,
    },
    states: {
      type: Array,
      default: null,
    },
    categories: {
      type: Array,
      default: null,
    },
    playerStatuses: {
      type: Array,
      default: null,
    },
    staffJobPositions: {
      type: Array,
      default: null,
    },
    genders: {
      type: Array,
      default: null,
    },
    sectionPlayer: {
      type: String,
      default: null,
    },
    sectionCoachingStaff: {
      type: String,
      default: null,
    },
    option: {
      type: String,
      default: null,
    },
    teams: {
      type: [Object, Array],
      default: null,
    },
    jerseyNumbers: {
      type: [Object, Array],
      default: null,
    },
    colorPrimaryBtn: {
      type: Object,
      default: null,
    },
    genderSelect: {
      type: String,
      default: null,
    },

  },
  data() {
    return {
      required,
      isLoadingSave: false,
      idCountry: null,
      applyOther: false,
      memberPlayerStatusKey: null,
      tempQuery: {
        filtersObj: {},
        paramsObj: {},
      },
      clubStates: [],
      filterJersey: {
        page: 1,
        perPage: 50,
        teamId: null,
      },
    }
  },
  computed: {
    checkDeleted() {
      const { key } = this.member.member_type
      if (key === this.sectionPlayer) {
        return this.checkPermission(['DELETE_MEMBERS_PLAYERS', 'ALLOW_ALL'])
      }
      if (key === this.sectionCoachingStaff) {
        return this.checkPermission(['DELETE_MEMBERS_COACHING_STAFF', 'ALLOW_ALL'])
      }
      return false
    },
  },
  watch: {
    '$i18n.locale': function i18nlocale() {
      this.getStaffJobPositions()
    },
    'member.club.country_hash': function idCountry(pCountry) {
      if (pCountry !== null) {
        // this.fetchCitiesByCountryId(pCountry)
        this.getClubStates(pCountry)
      } else {
        this.member.club.state_hash = null
      }
      this.member.club_hash = null
    },
    'member.club.state_hash': function idCountry(ctry) {
      if (ctry !== null) {
        // this.tempQuery.filtersObj.state_hash = ctry
        this.getClubs()
      } else {
        this.member.club.state_hash = null
      }
      this.member.club_hash = null
    },
    applyOther(value) {
      if (value) {
        this.member.staff_job_position_hash = null
      } else {
        this.member.another_job = ''
      }
    },
    'member.gender_hash': function memberGenderId(id) {
      if (id != null) {
        this.tempQuery.filtersObj.gender_hash = id
        this.getStaffJobPositions()
        this.member.staff_job_position_hash = null
      }
    },
    'member.club_hash': function memberSportClubId(clubId) {
      if (clubId != null) {
        // this.fetchTeamByIdClub(clubId)
        this.tempQuery.filtersObj.club_hash = clubId
        this.getTeams()
      } else {
        this.member.team_hash = null
      }
    },
    'member.team_hash': function memberTeamId(teamId) {
      if (teamId) {
        this.filterJersey.team_hash = teamId
        this.getCategories()
        // this.jerseyNumbersByteamId(this.filterJersey)
        this.handleJerseyByTeamId(this.filterJersey)
      }
    },
    'member.player_status': function memberPlayerStatus(sta) {
      this.getPlayerStatus(sta)
    },
  },

  mounted() {
    this.getClubStates()
    this.tempQuery.filtersObj.state_hash = this.member.club.state_hash
    this.tempQuery.filtersObj.club_hash = this.member.club.hash
    this.tempQuery.filtersObj.gender_hash = this.member.gender_hash
    this.getClubs()
    this.getTeams()
    this.getCategories()
    this.handleJerseyByTeamId()
    if (isObject(this.member.player_status) && hasKeyObject(this.member.player_status, 'key')) {
      this.memberPlayerStatusKey = this.member.player_status.key
    }

    if (this.member.another_job) {
      this.applyOther = true
    }
  },
  methods: {
    isObject,
    hasKeyObject,
    validatorFileSize,
    validatorFileType,
    checkPermission,
    ...mapActions({
      fetchClubs: 'fetchClubs',
      fetchStateByCountryId: 'fetchStateByCountryId',
      jerseyNumbersByteamId: 'teamStore/jerseyNumbersByteamId',
      update: 'memberStore/update',
      destroy: 'memberStore/destroy',
      updateStatus: 'memberStore/updatePlayerStatus',
      fetchTeamByIdClub: 'teamStore/fetchTeamByIdClub',
      // fetchJobStaffById: 'fetchJobStaffById',
      fetchStaffJobPositions: 'catalogs/fetchStaffJobPositions',
      fetchDataTeams: 'teamStore/fetchData',
      fetchCategories: 'catalogs/fetchCategories',
      mediaFileUpload: 'mediaFile/upload',
      mediaFileDestroy: 'mediaFile/destroy',
    }),
    // TODO: update
    async onUpdateMember() {
      this.isLoadingSave = true
      if (this.applyOther) {
        this.member.staff_job_position_hash = null
        this.member.staff_job_position = null
      } else {
        this.member.another_job = null
      }
      // const errors = await this.$refs.form1Update.errors
      this.$refs.form1Update.validate().then(isValid => {
        if (!isValid && this.member.is_complete_data === true) {
          this.showError(this.$t('fields-are-missing-to-complete'))
          this.isLoadingSave = false
          return
        }
        this.update(this.member).then(response => {
          this.responseSuccessUpdate(response.data.message)
          this.isLoadingSave = false
          this.$emit('hidden-form')
        }).catch(error => {
          this.isLoadingSave = false
          this.responseCatch(error)
        }).finally(() => {
          this.isLoadingSave = false
        })
      })
    },
    async onRemoveMember() {
      await this.sampleDeleteQuestion()
        .then(async result => {
          if (!result.isConfirmed) {
            return
          }
          await this.destroy(this.member.hash).then(response => {
            this.responseSuccessElimination(response.data.message)
            this.$router.push({ name: 'member' })
          })
        }).catch(error => {
          this.responseCatch(error)
        })
    },
    handleJerseyByTeamId(query) {
      this.filterJersey.team_hash = this.member.team_hash
      if (query) {
        this.filterJersey.perPage = query.perPage
        this.filterJersey.page = query.page
      }
      // this.fetchJerseyByTeamId(this.filterJersey)
      this.jerseyNumbersByteamId(this.filterJersey)
    },
    async getStaffJobPositions() {
      await this.fetchStaffJobPositions(this.tempQuery).then(() => {
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    assignFileContract(responseFile) {
      const previousFileHash = this.member.path_file_contract_media_file_hash
      if (previousFileHash != null) {
        this.mediaFileDestroy(previousFileHash)
          .then(() => {
          }).catch(error => {
            this.responseCatch(error)
          })
      }
      this.$set(this.member, 'file_contract', responseFile)
      this.$set(this.member, 'path_file_contract_media_file_hash', responseFile.hash)
    },
    async getClubs() {
      // this.tempQuery.filtersObj.state_hash = idState
      await this.fetchClubs(this.tempQuery).then(response => {
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    async getClubStates() {
      this.pCountry = this.member.club.country_hash
      await this.fetchStateByCountryId(this.pCountry).then(response => {
        this.clubStates = response.data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    async getTeams() {
      await this.fetchDataTeams(this.tempQuery).then(() => {
      }).catch(error => {
        this.responseCatch(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
    async getCategories() {
      await this.fetchCategories(this.tempQuery).then(() => {
      }).catch(error => {
        this.responseCatch(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
    async getPlayerStatus(sta) {
      if (isObject(sta)) {
        this.member.player_status_hash = sta.id
        this.memberPlayerStatusKey = sta.key
      } else {
        this.member.contractual_condition.transfer.ceding_club = null
      }
    },
    async fileValidation(fileData, typeField) {
      // const validSize = this.validatorFileSize(fileData)
      const validType = this.validatorFileType(fileData)

      // if (!validSize) {
      //   if (typeField === 'avatar_file') this.path_file_avatar = null
      //
      //   this.showError(`${this.$t('the-file-size-exceeds-the-allowed-size')}: 2MB`)
      //   return
      // }
      if (!validType) {
        if (typeField === 'avatar_file') this.path_file_avatar = null

        this.showError(`${this.$t('only-documents-in-PDF-and-image-format-are-allowed')}`)
        return
      }
      this.saveFileUpload(fileData, typeField)
    },
    async saveFileUpload(fileData, typeField) {
      const formData = new FormData()
      formData.append('file', fileData)
      formData.append('folder', 'members')
      formData.append('club_id', this.member.club_hash)
      await this.mediaFileUpload(formData).then(response => {
        const { data } = response.data
        // this.showSuccess(this.$t('generic.document'), this.$t('the-image-has-been-stored-correctly'))
        if (typeField === 'avatar_file') {
          const previousFileHash = this.member.avatar_file_media_file_hash
          if (previousFileHash != null) {
            this.mediaFileDestroy(previousFileHash)
              .then(() => {
              }).catch(error => {
                this.responseCatch(error)
              })
          }
          this.$set(this.member, 'avatar_file', data)
          this.$set(this.member, 'avatar_file_media_file_hash', data.hash)
        }
      }).catch(error => {
        this.responseCatch(error)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.repeater-form {
  overflow: visible;
  transition: .35s height;
}
.mb-30 {
  margin-bottom: 30px;
}
.btn-right {
  float: right !important;
}
.mt-18 {
  margin-top: 18px;
}
.btn-footer-modal {
    float: right
  }
@media (max-width: 450px) {
  .btn-footer-modal {
    float: none !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
</style>
