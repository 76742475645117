var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',[_c('validation-observer',{ref:"form2Update"},[_c('b-form',[_c('head-title-wizard',{attrs:{"section-player":_vm.sectionPlayer,"section-coaching-staff":_vm.sectionCoachingStaff,"option":_vm.option,"gender":_vm.genderSelect ? _vm.genderSelect : ''}}),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',[_c('div',{staticClass:"text-center"},[_c('upload-image',{attrs:{"tooltip-text":'generic.allowedFormat',"button-text":'generic.uploadPhoto',"avatar-icon":'icon-0-icons-dark-avatar',"image-size":'10rem',"apply-b-link":true,"disabled":_vm.member.club_hash == null,"path-file-avatar":_vm.member.avatar_file ? _vm.member.avatar_file.url_thumb : ''},on:{"intance-file-x":function($event){return _vm.fileValidation($event, 'avatar_file')}}}),_c('br')],1)])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('member.form.label.name'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:254|nameRegex","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('member.form.placeholder.enterName')},model:{value:(_vm.member.name),callback:function ($$v) {_vm.$set(_vm.member, "name", $$v)},expression:"member.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('member.form.label.firstName'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:254|nameRegex","name":"firstName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('member.form.placeholder.enterFirstName')},model:{value:(_vm.member.first_name),callback:function ($$v) {_vm.$set(_vm.member, "first_name", $$v)},expression:"member.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('member.form.label.lastName')))]),_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('member.form.placeholder.enterLastName')},model:{value:(_vm.member.last_name),callback:function ($$v) {_vm.$set(_vm.member, "last_name", $$v)},expression:"member.last_name"}})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"3","lg":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('member.form.label.birthday'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"birthday"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"form-control",attrs:{"locale":_vm.$i18n.locale,"max":new Date(),"placeholder":_vm.$t('member.form.placeholder.selectDate'),"state":errors.length > 0 ? false : null},model:{value:(_vm.member.birthday),callback:function ($$v) {_vm.$set(_vm.member, "birthday", $$v)},expression:"member.birthday"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"3","lg":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('generic.nameLegalResponsible'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":_vm.validAge >= 18 ? '' : 'required',"name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('member.form.placeholder.enterName'),"disabled":_vm.validAge >= 18},model:{value:(_vm.member.holder_name),callback:function ($$v) {_vm.$set(_vm.member, "holder_name", $$v)},expression:"member.holder_name"}}),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t('generic.onlyMinors')))]),_vm._v(" "),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"3","lg":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('generic.countryOfBirth'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"countryBirth"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"club-country-id","label":"name","options":_vm.countries,"reduce":function (country) { return country.id; },"placeholder":_vm.$t('generic.selectCountry'),"state":errors.length > 0 ? false : null},model:{value:(_vm.member.country_birth_hash),callback:function ($$v) {_vm.$set(_vm.member, "country_birth_hash", $$v)},expression:"member.country_birth_hash"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"3","lg":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('generic.stateOfBirth'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"stateBirth"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"club-state-id","label":"name","options":_vm.memberBirthStates,"reduce":function (city) { return city.hash; },"placeholder":_vm.$t('generic.selectState'),"state":errors.length > 0 ? false : null,"disabled":_vm.member.country_birth_hash == null},model:{value:(_vm.member.state_birth_hash),callback:function ($$v) {_vm.$set(_vm.member, "state_birth_hash", $$v)},expression:"member.state_birth_hash"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('member.form.label.lastDegreeStudies'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"lastDegreeStudies"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"studies-id","label":"name","options":_vm.educationLevels,"reduce":function (level) { return level.id; },"placeholder":_vm.$t('member.form.placeholder.selectStudies'),"state":errors.length > 0 ? false : null},model:{value:(_vm.member.education_level_hash),callback:function ($$v) {_vm.$set(_vm.member, "education_level_hash", $$v)},expression:"member.education_level_hash"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('member.form.label.email'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('member.form.placeholder.enterEmail')},model:{value:(_vm.member.email),callback:function ($$v) {_vm.$set(_vm.member, "email", $$v)},expression:"member.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('member.form.label.cellPhone'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"cellPhone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"valid-color":"#98a1b3","error-color":"#cc296a","color":"#cc296a","default-country-code":"MX","translations":{
                    countrySelectorLabel: _vm.$t('generic.telephonePrefix'),
                    countrySelectorError: _vm.$t('generic.chooseCountry'),
                    phoneNumberLabel: _vm.$t('member.form.placeholder.enterCellPhone'),
                    example: _vm.$t('generic.example')
                  }},on:{"update":function($event){_vm.resultsCellPhone = $event}},model:{value:(_vm.member.cell_phone),callback:function ($$v) {_vm.$set(_vm.member, "cell_phone", $$v)},expression:"member.cell_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-18",attrs:{"md":"8"}},[_c('span',[_vm._v(_vm._s(_vm.$t('member.form.label.currentAddress')))]),_c('hr')]),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t("accountSettings.address"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:255|addressRegex","name":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('member.form.placeholder.enterAddressFull')},model:{value:(_vm.member.address.address),callback:function ($$v) {_vm.$set(_vm.member.address, "address", $$v)},expression:"member.address.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"postal-code"}},[_vm._v(" "+_vm._s(_vm.$t("accountSettings.postalCode"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:255|zipRegex","name":"postalCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"postal-code","type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('accountSettings.postalCode')},model:{value:(_vm.member.address.zip_code),callback:function ($$v) {_vm.$set(_vm.member.address, "zip_code", $$v)},expression:"member.address.zip_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"club-country-id"}},[_vm._v(_vm._s(_vm.$t('generic.country'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"club-country-id","label":"name","options":_vm.countries,"reduce":function (country) { return country.id; },"placeholder":_vm.$t('generic.selectCountry'),"state":errors.length > 0 ? false : null},model:{value:(_vm.member.address.country_hash),callback:function ($$v) {_vm.$set(_vm.member.address, "country_hash", $$v)},expression:"member.address.country_hash"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"club-state-id"}},[_vm._v(_vm._s(_vm.$t('generic.state'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"state"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"club-state-id","label":"name","options":_vm.memberAddressStates,"reduce":function (city) { return city.hash; },"disabled":_vm.member.address.country_hash == null,"placeholder":_vm.$t('generic.selectState')},model:{value:(_vm.member.address.state_hash),callback:function ($$v) {_vm.$set(_vm.member.address, "state_hash", $$v)},expression:"member.address.state_hash"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('div',{staticClass:"btn-footer-modal"},[_c('b-button',{staticStyle:{"margin-left":"17px"},attrs:{"variant":"secondary","pill":""},on:{"click":function($event){return _vm.$emit('hidden-form')}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),_c('overlay-button',{attrs:{"loading":_vm.isLoadingSave}},[_c('b-button',{staticStyle:{"margin-left":"17px"},style:(_vm.colorPrimaryBtn),attrs:{"variant":"primary","pill":""},on:{"click":_vm.onUpdateMember}},[_vm._v(" "+_vm._s(_vm.$t("buttons.save"))+" ")])],1)],1),_c('div',{staticClass:"float-right"},[(_vm.checkDeleted)?_c('b-button',{staticClass:"d-none d-sm-none d-md-block",staticStyle:{"margin-left":"17px"},attrs:{"variant":"outline-secondary","pill":""},on:{"click":_vm.onRemoveMember}},[_c('i',{staticClass:"icon-0-icons-dark-trash-can mr-25"}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('buttons.delete')))])]):_vm._e()],1),(_vm.checkDeleted)?_c('b-button',{staticClass:"d-block d-sm-block d-md-none w-100 mt-2",attrs:{"variant":"outline-secondary","pill":""},on:{"click":_vm.onRemoveMember}},[_c('i',{staticClass:"icon-0-icons-dark-trash-can mr-25"}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('buttons.delete')))])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }