<template>
  <div>
    <b-row align-h="center">
      <b-col>
        <div
          v-if="avatar != null"
          class="text-center"
        >
          <b-avatar
            size="10rem"
            :src="avatar.url_thumb"
            class="i-shadow-new mb-1"
            @input="uploadImage"
          />
        </div>
        <div
          v-else
          class="text-center"
        >
          <b-avatar
            size="10rem"
            @click="$refs.refInputEl.click()"
          />
          <!--/ avatar -->
          <p class="mt-1">
            <input
              ref="refInputEl"
              type="file"
              class="d-none"
              accept=".jpg, .png, .gif"
              @input="uploadImage"
            >
            <!--/ file -->
          </p>
        </div>
        <div class="text-center">
          <h2> <b>{{ fullName }}</b> </h2>
          <div v-if=" number != null">
            <h4>{{ $t('generic.no') + ' ' + number }}</h4>
          </div>
          <div v-if="anotherJob != null">
            <h4> {{ anotherJob }} </h4>
          </div>
          <div v-else-if="job != null">
            <h4> {{ job }} </h4>
          </div>
          <div v-if="age != null">
            <h4>{{ age }}</h4>
          </div>
          <div v-if=" number != null">
            <h4 v-if="playerStatus"><b> {{ playerStatus.name }}</b>
              <span v-if="playerStatus.key != 'ACTIVE' && cedingClub != null "> {{ cedingClub }} </span>
            </h4>
          </div>

        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  props: {
    avatar: {
      type: Object,
      default: null,
    },
    fullName: {
      type: String,
      default: null,
    },
    age: {
      type: String,
      default: null,
    },
    number: {
      type: [String, Number],
      default: null,
    },
    job: {
      type: String,
      default: null,
    },
    anotherJob: {
      type: String,
      default: null,
    },
    playerStatus: {
      type: Object,
      default: null,
    },
    // statusName: {
    //   type: String,
    //   default: null,
    // },
    cedingClub: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
    //
    }
  },
  methods: {
    uploadImage(obj) {

    },
  },
}
</script>
<style>
</style>
