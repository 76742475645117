<template>
  <div>
    <b-list-group-item>
      <b-row>
        <b-col md="6">
          <strong class="float-left">{{ $t('representative-or-agent') }}</strong>
        </b-col>
        <b-col md="6">
          <span
            v-if="agreement.assign_representative_key != null && agreement.assign_representative != null "
            class="float-left"
          >
            {{ agreement.assign_representative.name }}
          </span>
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item>
      <b-row>
        <b-col md="6">
          <strong class="float-left">{{ $t('signature-date') }}</strong>
        </b-col>
        <b-col md="6">
          <span
            v-if="agreement.signature_date"
            class="float-left"
          >{{ agreement.signature_date | formatDate }}</span>
        </b-col>
      </b-row>
    </b-list-group-item>

    <!-- Vigencia -->
    <b-list-group-item>
      <b-row>
        <b-col md="6">
          <strong class="float-left">{{ $t('generic.validity') }}</strong>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col
              md="6"
            >
              <strong class="float-left">{{ $t('generic.initDate') }}</strong>
              <span
                v-if="agreement.init_date"
                class="float-left"
              >&nbsp;{{ agreement.init_date | formatDate }}</span>
            </b-col>
            <b-col
              md="6"
            >
              <strong class="float-left">{{ $t('generic.endDate') }}</strong>
              <span
                v-if="agreement.end_date"
                class="float-left"
              >&nbsp;{{ agreement.end_date | formatDate }}</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item>
      <b-row>
        <b-col md="6">
          <strong class="float-left">{{ $t('consideration') }}</strong>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col
              md="6"
            >
              <strong class="float-left">{{ $t('fixed-quantity') }}</strong>
              <span
                v-if="agreement.against_provision.has_fixed_quantity"
                class="float-left"
              >&nbsp;{{ agreement.against_provision.fixed_quantity }}</span>
            </b-col>
            <b-col
              md="6"
            >
              <strong class="float-left">{{ $t('variable-quantity') }}</strong>
              <span
                v-if="agreement.against_provision.has_variable_quantity"
                class="float-left"
              >&nbsp;{{ agreement.against_provision.variable_quantity }}</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-list-group-item>
  </div>
</template>
<script>
export default {
  props: {
    agreement: {
      type: Object,
      default: null,
    },
  },
}
</script>
<style lang="">

</style>
