<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col md="6">
            <h5 class="mt-1">
              <strong>{{ $t('contractual-conditions') }}</strong>
            </h5>
          </b-col>
          <b-col
            v-if="checkPermission(['EDIT_MEMBERS_CONTRACTUAL_CONDITIONS', 'ALLOW_ALL']) && showEditButton === true"
            md="6"
          >
            <span
              class="float-right mt-1"
            >
              {{ $t( 'buttons.edit') }}
              <b-button
                class="btn-icon rounded-circle"
                variant="secondary"
                @click="$emit('edit-conditions', conditions)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </span>
          </b-col>
        </b-row>
        <hr style="margin-top: 8px;">
      </b-col>
      <b-col md="12">
        <b-list-group>
          <b-list-group-item
            :disabled="conditions.type_contractual_conditions != 'TRANSFER'"
            variant="primary"
          >
            <b-row
              class="cursor-pointer"
              @click="collapseSection('collapse-' + conditions.hash)"
            >
              <b-col md="6">
                <strong class="float-left">{{ $t('origin') }}</strong>
              </b-col>
              <b-col
                class="d-flex justify-content-between align-items-center"
                md="6"
              >
                <span v-if="hasKeyObject(conditions, 'type_contractual_conditions') && conditions.type_contractual_conditions == 'TRANSFER'">
                  {{ $t('transfer') }}
                </span>
                <span v-else>
                  {{ $t('free-of-contract') }}
                </span>
                <!-- <span
                  v-if="conditions.type_contractual_conditions"
                  class="float-left"
                >{{ conditions.type_contractual_conditions }}</span> -->
                <feather-icon
                  icon="ChevronDownIcon"
                  size="16"
                />
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item v-if="option !== sectionCoachingStaff">
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('generic.description') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-if="conditions.descrition_contractual_conditions"
                  class="float-left"
                >{{ conditions.descrition_contractual_conditions }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
        <b-list-group>

          <!-- Transfer -->
          <b-collapse
            :id="'collapse-' + conditions.hash"
          >
            <b-list-group v-if="conditions.type_contractual_conditions === 'TRANSFER'">
              <b-list-group-item>
                <b-row>
                  <b-col md="6">
                    <strong class="float-left">{{ $t('transfer') }}</strong>
                  </b-col>
                  <b-col md="6">
                    <span
                      v-if="conditions.transfer.operations"
                      class="float-left"
                    >{{ conditions.transfer.operations }}</span>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item v-if="conditions.transfer.operations === 'INTERNATIONAL'">
                <b-row>
                  <b-col md="6">
                    <strong class="float-left">{{ $t('number-tms') }}</strong>
                  </b-col>
                  <b-col md="6">
                    <span
                      v-if="conditions.transfer.tms_number"
                      class="float-left"
                    >{{ conditions.transfer.tms_number }}</span>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item>
                <b-row>
                  <b-col md="6">
                    <strong class="float-left">{{ $t('type-of-operation') }}</strong>
                  </b-col>
                  <b-col md="6">
                    <b-row v-if="conditions.transfer.type_operation">
                      <b-col cols="12">
                        <span
                          class="float-left"
                        >{{ conditions.transfer.type_operation.name }}</span>
                      </b-col>
                      <b-col
                        v-if="conditions.transfer.type_operation.key === 'TEMPORARY'"
                        md="6"
                      >
                        <strong class="float-left">{{ $t('generic.initDate') }}</strong>
                        <span
                          v-if="conditions.transfer.init_date_operation"
                          class="float-left"
                        >&nbsp;{{ conditions.transfer.init_date_operation }}</span>
                      </b-col>
                      <b-col
                        v-if="conditions.transfer.type_operation.key === 'TEMPORARY'"
                        md="6"
                      >
                        <strong class="float-left">{{ $t('generic.endDate') }}</strong>
                        <span
                          v-if="conditions.transfer.end_date_operation"
                          class="float-left"
                        >&nbsp;{{ conditions.transfer.end_date_operation }}</span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item>
                <b-row>
                  <b-col md="6">
                    <strong class="float-left">{{ $t('club-cedente') }}</strong>
                  </b-col>
                  <b-col md="6">
                    <span
                      v-if="conditions.transfer.ceding_club"
                      class="float-left"
                    >{{ conditions.transfer.ceding_club }}</span>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item>
                <b-row>
                  <b-col md="6">
                    <strong class="float-left">{{ $t('percentage-of-transfer-of-economic-rights') }}</strong>
                  </b-col>
                  <b-col md="6">
                    <span
                      v-if="conditions.transfer.percentage_transfer_economic_rights"
                      class="float-left"
                    >{{ conditions.transfer.percentage_transfer_economic_rights }}</span>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item>
                <b-row>
                  <b-col md="6">
                    <strong class="float-left">{{ $t('transfer-conditions') }}</strong>
                  </b-col>
                  <b-col md="6">
                    <span
                      v-if="conditions.transfer.transfer_conditions != null"
                      @click="collapseSection('collapse-' + conditions.hash + 1)"
                    >
                      <span
                        class="cursor-pointer"
                        v-html="conditions.transfer.transfer_conditions.substring(0,55)+'...Ver'"
                      />
                    </span>
                  </b-col>
                </b-row>
              </b-list-group-item>

              <b-collapse
                :id="'collapse-' + conditions.hash + 1"
              >
                <b-list-group-item>
                  <b-row>
                    <b-col md="12">
                      <span v-html="conditions.transfer.transfer_conditions" />
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-collapse>

              <!-- Acuerdo o contrato -->
              <b-list-group-item>
                <b-row>
                  <b-col md="6">
                    <strong class="float-left">{{ $t('agreement-or-contract') }}</strong>
                  </b-col>
                  <b-col md="6">
                    <span v-if="hasKeyObject(conditions.transfer, 'contract_agreement') && conditions.transfer.contract_agreement == 'YES'">
                      {{ $t('generic.yes') }}
                    </span>
                    <span v-else>
                      {{ $t('generic.not') }}
                    </span>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <template
                v-if="conditions.transfer.contract_agreement === 'YES'"
              >
                <!-- representantive, signature date, init end and Contraprestacion -->
                <agreement-date
                  :agreement="conditions.transfer"
                />

                <!-- Forma de pago -->
                <b-list-group-item>
                  <b-row>
                    <b-col md="6">
                      <strong class="float-left">{{ $t('method-payment') }}</strong>
                    </b-col>
                    <b-col md="6">
                      <span
                        v-if="conditions.transfer.method_payment"
                        class="float-left"
                      >{{ conditions.transfer.method_payment }}</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>

                <!-- Periodicidad -->
                <b-list-group-item>
                  <b-row>
                    <b-col md="6">
                      <strong class="float-left">{{ $t('periodicity') }}</strong>
                    </b-col>
                    <b-col md="6">
                      <span
                        v-if="conditions.transfer.payment_period"
                        class="float-left"
                      >{{ conditions.transfer.payment_period.name }}</span>
                      <span
                        v-if="hasKeyObject(conditions.transfer.payment_period, 'key') && (conditions.transfer.payment_period.key == 'OTHER')"
                        class="float-left"
                      >&nbsp;&nbsp;{{ conditions.transfer.payment_period_other }}</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>

                <!-- Penalizacion -->
                <b-list-group-item>
                  <b-row>
                    <b-col md="6">
                      <strong class="float-left">{{ $t('amount-of-penalty') }}</strong>
                    </b-col>
                    <b-col md="6">
                      <span
                        v-if="conditions.transfer.penalty_amount"
                        class="float-left"
                      >{{ conditions.transfer.penalty_amount }}</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>

                <!-- Dimicilio de notificacion -->
                <details-address
                  :address="conditions.transfer.address"
                />

                <!-- Correo electronico -->
                <b-list-group-item>
                  <b-row>
                    <b-col md="6">
                      <strong class="float-left">{{ $t('generic.email') }}</strong>
                    </b-col>
                    <b-col md="6">
                      <span
                        v-if="conditions.transfer.email != null"
                        class="float-left"
                      >
                        {{ conditions.transfer.email }}
                      </span>
                    </b-col>
                  </b-row>
                </b-list-group-item>

                <!-- Jurisdiction -->
                <b-list-group-item>
                  <b-row>
                    <b-col md="6">
                      <strong class="float-left">{{ $t('jurisdiction-and-jurisdiction') }}</strong>
                    </b-col>
                    <b-col md="6">
                      <span
                        v-if="conditions.transfer.type_jurisdiction"
                        class="float-left"
                      >{{ conditions.transfer.type_jurisdiction.name }}</span>
                      <span
                        v-if="hasKeyObject(conditions.transfer.type_jurisdiction, 'key') && (conditions.transfer.type_jurisdiction.key == 'OTHER' || conditions.transfer.type_jurisdiction.key === 'FEDERATION')"
                        class="float-left"
                      >&nbsp;&nbsp;{{ conditions.transfer.jurisdiction_other }}</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </template>
              <!-- End Contract or acuerdo -->
            </b-list-group>
            <b-list-group class="mt-1">

              <b-list-group-item
                v-if="conditions.transfer.player_agent"
                variant="primary"
                class="cursor-pointer"
                :disabled="conditions.transfer.player_agent_key === 'NO'"
              >
                <!-- <b-row @click="collapseSection('collapse-transfer-agent-' + conditions.transfer.player_agent.hash)"> -->
                <b-row @click="collapseSection('collapse-transfer-agent-' + conditions.hash + conditions.transfer.player_agent.hash)">
                  <b-col md="6">
                    <strong class="float-left">{{ $t('question.playerAgent') }}</strong>
                  </b-col>
                  <b-col
                    class="d-flex justify-content-between align-items-center"
                    md="6"
                  >
                    <span v-if="hasKeyObject(conditions.transfer, 'player_agent_key') && conditions.transfer.player_agent_key == 'YES'">
                      {{ $t('generic.yes') }}
                    </span>
                    <span v-else>
                      {{ $t('generic.not') }}
                    </span>
                    <feather-icon
                      icon="ChevronDownIcon"
                      size="16"
                    />
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-collapse
                v-if="conditions.transfer.player_agent"
                :id="'collapse-transfer-agent-' + conditions.hash + conditions.transfer.player_agent.hash"
              >
                <template v-if="conditions.transfer.player_agent_key === 'YES'">
                  <details-agent
                    :agent="conditions.transfer.player_agent"
                  />
                </template>
              </b-collapse>
              <!-- End Agent transfer -->
            </b-list-group>
          </b-collapse>
          <!-- End transfer -->

          <!-- general -->
          <!-- type contract -->
        </b-list-group>
        <b-list-group>

          <!-- title or licence -->
          <b-list-group-item v-if="option === sectionCoachingStaff">
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('title-or-licence') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-if="conditions.title_or_licence"
                  class="float-left"
                >{{ conditions.title_or_licence }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>

          <!-- Type contract -->
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('type-contract') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-if="conditions.type_contract"
                  class="float-left"
                >{{ conditions.type_contract.name }}</span>
                <span
                  v-if="hasKeyObject(conditions.type_contract, 'key') && conditions.type_contract.key == 'OTHER'"
                  class="float-left"
                >&nbsp;&nbsp;{{ conditions.type_contract_other }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>

          <agreement-date
            :agreement="conditions"
          />

          <b-list-group-item
            v-for="(item, index) in conditions.increments"
            :key="item.id + 1"
          >
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('increase') }} - {{ index + 1 }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ conditions.increments[index].value }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>

          <!-- Condiciones de incremento -->
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('increase-conditions') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-if="conditions.increase_conditions != null"
                  @click="collapseSection('collapse-' + conditions.hash + 2)"
                >
                  <span
                    class="cursor-pointer"
                    v-html="conditions.increase_conditions.substring(0,55)+'...Ver'"
                  />
                </span>
              </b-col>
            </b-row>
          </b-list-group-item>

          <b-collapse
            :id="'collapse-' + conditions.hash + 2"
          >
            <b-list-group-item>
              <b-row>
                <b-col md="12">
                  <span v-html="conditions.increase_conditions" />
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-collapse>

          <!-- Forma de pago -->
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('method-payment') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-if="conditions.method_payment"
                  class="float-left"
                >{{ conditions.method_payment }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>

          <!-- Periodicidad -->
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('periodicity') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-if="conditions.payment_period"
                  class="float-left"
                >{{ conditions.payment_period.name }}</span>
                <span
                  v-if="hasKeyObject(conditions.payment_period, 'key') && (conditions.payment_period.key == 'OTHER')"
                  class="float-left"
                >&nbsp;&nbsp;{{ conditions.payment_period_other }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>

          <!-- Bonos -->
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('bonds') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-if="conditions.bonds"
                  class="float-left"
                >{{ conditions.bonds }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>

          <b-list-group-item
            v-for="(item, indexB) in conditions.benefits"
            :key="item.id"
          >
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('other-benefits') }} ({{ indexB + 1 }})</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-if="conditions.benefits[indexB].value != null"
                  @click="collapseSection('collapse-' + conditions.hash + conditions.benefits[indexB].id)"
                >
                  <span
                    class="cursor-pointer"
                    v-html="conditions.benefits[indexB].value.substring(0,55)+'...Ver'"
                  />
                </span>
                <!-- <span class="float-left">{{ conditions.benefits[indexB].value }}</span> -->
              </b-col>
            </b-row>
            <b-collapse
              :id="'collapse-' + conditions.hash + conditions.benefits[indexB].id"
            >
              <!-- <b-list-group-item> -->
              <b-row>
                <b-col md="12">
                  <span v-html="conditions.benefits[indexB].value" />
                </b-col>
              </b-row>
              <!-- </b-list-group-item> -->
            </b-collapse>
          </b-list-group-item>

          <!-- Indemnizacion por terminacion anticipada  -->
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('compensation-for-early-termination') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-if="conditions.compensation_amount"
                  class="float-left"
                >{{ conditions.compensation_amount }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>

          <!-- Indemnizacion -->
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('compensation-details') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-if="conditions.compensation_details != null"
                  @click="collapseSection('collapse-' + conditions.hash + 3)"
                >
                  <span
                    class="cursor-pointer"
                    v-html="conditions.compensation_details.substring(0,55)+'...Ver'"
                  />
                </span>
                <!-- <span
                  v-if="conditions.compensation_details"
                  class="float-left"
                >{{ conditions.compensation_details }}</span> -->
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-collapse
            :id="'collapse-' + conditions.hash + 3"
          >
            <b-list-group-item>
              <b-row>
                <b-col md="12">
                  <span v-html="conditions.compensation_details" />
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-collapse>

          <!-- Penalizacion -->
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('penalty') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-if="conditions.penalty_amount"
                  class="float-left"
                >{{ conditions.penalty_amount }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>

          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('penalty-details') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-if="conditions.penalty_details != null"
                  @click="collapseSection('collapse-' + conditions.hash + 4)"
                >
                  <span
                    class="cursor-pointer"
                    v-html="conditions.penalty_details.substring(0,55)+'...Ver'"
                  />
                </span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-collapse
            :id="'collapse-' + conditions.hash + 4"
          >
            <b-list-group-item>
              <b-row>
                <b-col md="12">
                  <span v-html="conditions.penalty_details" />
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-collapse>

          <!-- Rescision -->
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('rescission') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-if="conditions.rescission_amount"
                  class="float-left"
                >{{ conditions.rescission_amount }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>

          <!-- Details Rescision -->
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('rescission-details') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-if="conditions.rescission_details != null"
                  @click="collapseSection('collapse-' + conditions.hash + 5)"
                >
                  <span
                    class="float-left cursor-pointer"
                    v-html="conditions.rescission_details.substring(0,55)+'...Ver'"
                  />
                </span>
              </b-col>
            </b-row>
          </b-list-group-item>

          <b-collapse
            :id="'collapse-' + conditions.hash + 5"
          >
            <b-list-group-item>
              <b-row>
                <b-col md="12">
                  <span v-html="conditions.rescission_details" />
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-collapse>

          <!-- Address -->
          <details-address
            :address="conditions.address"
          />

          <!-- Correo electronico -->
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('generic.email') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-if="conditions.email != null"
                  class="float-left"
                >
                  {{ conditions.email }}
                </span>
              </b-col>
            </b-row>
          </b-list-group-item>

          <!-- Agent -->
        </b-list-group>
        <b-list-group class="mt-1">

          <b-list-group-item
            v-if="conditions.player_agent"
            variant="primary"
            class="cursor-pointer"
            :disabled="conditions.player_agent_key === 'NO'"
          >
            <b-row @click="collapseSection('collapse-general-agent-' + conditions.hash + conditions.player_agent.hash)">
              <b-col md="6">
                <strong class="float-left">{{ $t('question.playerAgent') }}</strong>
              </b-col>
              <b-col
                class="d-flex justify-content-between align-items-center"
                md="6"
              >
                <span v-if="hasKeyObject(conditions, 'player_agent_key') && conditions.player_agent_key == 'YES'">
                  {{ $t('generic.yes') }}
                </span>
                <span v-else>
                  {{ $t('generic.not') }}
                </span>
                <feather-icon
                  icon="ChevronDownIcon"
                  size="16"
                />
              </b-col>
            </b-row>
          </b-list-group-item>

          <!-- Agent general -->
          <b-collapse
            v-if="conditions.player_agent"
            :id="'collapse-general-agent-' + conditions.hash + conditions.player_agent.hash"
          >
            <b-list-group v-if="conditions.player_agent_key === 'YES'">
              <details-agent
                :agent="conditions.player_agent"
              />
            </b-list-group>
          </b-collapse>

        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { isObject, hasKeyObject } from '@core/utils/utils'
import DetailsAgent from './components/DetailsAgent.vue'
import AgreementDate from './components/AgreementDate.vue'
import DetailsAddress from './components/DetailsAddress.vue'

export default {
  components: {
    DetailsAgent,
    AgreementDate,
    DetailsAddress,
  },
  props: {
    conditions: {
      type: Object,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
    option: {
      type: String,
      default: null,
    },
    sectionCoachingStaff: {
      type: String,
      default: null,
    },
    showEditButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    isObject,
    hasKeyObject,
    collapseSection(idSection) {
      if (idSection) { this.$root.$emit('bv::toggle::collapse', idSection) }
    },
  },
}
</script>
