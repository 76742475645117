<template>
  <b-card>
    <b-row>
      <b-col class="col-12">
        <h2 class="i-margin-bottom-submodule">
          {{ $t('Details') }}
        </h2>
        <hr>
      </b-col>
      <b-col class="col-12 pt-1 justify-content-end d-md-inline-flex d-none">
        <ul class="navs float-right">
          <li
            v-for="sectionC in sectionsInTab"
            :key="sectionC.id"
            class="i-li-active"
            :class="{'i-tab-option-active' : sectionC.id == optionActiveD}"
            @click="assignRouteY(sectionC.id)"
          >
            <span class="text-dark">
              <i
                v-if="sectionC.id === 3"
                class="icon-0-icons-dark-lock-closed"
              />
              {{ $t(sectionC.name) }}
            </span>
          </li>
        </ul>
      </b-col>
      <b-col class="col-md-12 justify-content-end">
        <div class="i-show-drop float-right mb-1">
          <div class="i-dropdown">
            <button class="i-drop-section-button">
              {{ sectionName }}
              <span style="margin-left: 5px">
                <feather-icon
                  icon="ChevronDownIcon"
                  size="16"
                  class="rotates"
                />
              </span>
            </button>
            <div class="i-dropdown-content">
              <div
                v-for="sectionD in sectionsInTab"
                :key="sectionD.id"
                :class="sectionD.id == optionActiveD ? 'i-nav-section-active' : ''"
                @click="assignRouteY(sectionD.id)"
              >
                {{ $t(sectionD.name) }}
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-overlay
      :show="isLoading"
    >
      <template #overlay>
        <div class="d-flex align-items-center justify-content-center mt-1">
          <b-spinner
            type="border"
            variant="primary"
          />
        </div>
        <div class="d-flex justify-content-center">
          <p class="pt-1">
            {{ $t('loading') }}
          </p>
        </div>
      </template>
      <b-row>

        <!-- Datos member -->
        <b-col>
          <div v-if="optionActiveD == 1">

            <!-- Buttons Edit and cancel -->
            <b-row
              v-if="checkEdit()"
              cols="12"
            >
              <b-col align-self="end">
                <span
                  v-if="!showForm1"
                  class="float-right ml-1"
                >
                  {{ $t( 'buttons.edit') }}
                  <b-button
                    class="btn-icon rounded-circle"
                    variant="secondary"
                    @click="showForm1 = true"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </span>
              </b-col>
            </b-row>
            <!-- End buttons -->

            <!-- Component edit -->
            <b-row
              v-if="showForm1"
              class="pl-1 pr-1"
            >
              <edit-member-form1
                :option="member.member_type.key"
                :member="member"
                :clubs="clubs"
                :countries="countries"
                :states="states"
                :categories="categories"
                :player-statuses="playerStatuses"
                :staff-job-positions="staffJobPositions"
                :section-coaching-staff="sectionCoachingStaff"
                :section-player="sectionPlayer"
                :gender-select="genderSelect"
                :genders="genders"
                :jersey-numbers="jerseyNumbers"
                :color-primary-btn="colorPrimaryBtn"
                :teams="teams.data"
                @hidden-form="handleUpdate"
              />
            </b-row>
            <!-- :section-player-male="sectionPlayerMale" -->
            <!-- :check-deleted="checkDeleted" -->
            <!-- End component edit -->

            <b-row
              v-if="!showForm1"
              class="match-height"
            >
              <b-col
                sm="12"
                md="5"
                lg="5"
              >
                <upload-member-image
                  :full-name="member.full_name"
                  :age="member.age"
                  :avatar="member.avatar_file"
                  :job="member.staff_job_position ? member.staff_job_position.name : null"
                  :number="member.jersey_number"
                  :another-job="member.another_job"
                  :player-status="member.player_status"
                  :ceding-club="member.contractual_condition ? member.contractual_condition.transfer.ceding_club : ''"
                />
              <!-- :status-name="member.statusName" -->
              </b-col>
              <!-- Description lists horizontal -->
              <b-col
                sm="12"
                md="7"
                lg="7"
              >
                <b-card no-body>
                  <b-card-body>
                    <dl class="row">
                      <dt class="col-sm-3">
                        <b>{{ $t('corporate.club') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        {{ member.club ? member.club.club_name : $t('generic.doesNotApply') }}
                      </dd>
                    </dl>
                    <dl
                      v-if="member.category"
                      class="row"
                    >
                      <dt class="col-sm-3">
                        <b>{{ $t('generic.category') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        {{ member.category ? member.category.name : $t('generic.doesNotApply') }}
                      </dd>
                    </dl>
                    <dl class="row">
                      <dt class="col-sm-3">
                        <b>{{ $t('generic.team') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        {{ member.team ? member.team.name : $t('generic.doesNotApply') }}
                      </dd>
                    </dl>
                    <dl class="row">
                      <dt class="col-sm-3">
                        <b>{{ $t('generic.validity') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        <b>{{ $t('generic.initDate') }}</b> {{ member.validity_init_date | formatDate }} <br> <b>{{ $t('generic.endDate') }}</b> {{ member.validity_end_date | formatDate }}
                      </dd>
                    </dl>
                    <dl class="row">
                      <dt class="col-sm-3 pt-1">
                        <b>{{ $t('member.form.label.contract') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        <div v-if="member.file_contract">
                          <b-media no-body>
                            <span
                              class="cursor-pointer mt-0 pt-0"
                              style="width: 35%;"
                            >
                              <feather-icon
                                icon="LockIcon"
                                size="18"
                                class="color-icon"
                                @click="showFile = !showFile"
                              />
                              <!-- @click="verifyPermissionAndPassword('VIEW_CONTRACT_FILE_MEMBERS')" -->
                              <span
                                class="mb-2"
                                @click="showFile = !showFile"
                              >&nbsp;&nbsp;{{ $t('generic.consult') }}</span>
                              <div class="float-right" style="display: flex;" v-if="checkPermission(['VIEW_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL']) && showFile == true">
                                <div v-if="isObject(member.file_contract) && member.file_contract.mime_type != 'application/pdf'">
                                  <image-viewer
                                    class="cursor-pointer mr-1"
                                    :document-id="member.path_file_contract_media_file_hash"
                                    :url-thumb="member.file_contract.url_thumb"
                                    :height="'30'"
                                    :width="'30'"
                                    :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                                    :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                                  />
                                </div>
                                <div v-else>
                                  <ViewerDocumentModal
                                    class="cursor-pointer mr-1"
                                    :title="member.file_contract ? member.file_contract.title : ''"
                                    :document-id="member.path_file_contract_media_file_hash"
                                    :height="'30'"
                                    :width="'30'"
                                    :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                                    :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                                  />
                                  <!-- :url-thumb="member.file_contract ? member.file_contract.url_thumb : null" -->
                                  <!-- Descarga de archivo -->
                                </div>
                                <div v-if="member.path_file_contract_media_file_hash != null && checkPermission(['DOWNLOAD_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL'])">
                                  <span
                                    class="float-right cursor-pointer"
                                    @click="verifyPermissionAndPassword('DOWNLOAD_CONTRACT_FILE_MEMBERS', member.path_file_contract_media_file_hash, member.file_contract.title)"
                                  >
                                    <!-- @click="handleDownloadFile(member.path_file_contract_media_file_hash, member.file_contract.title)" -->
                                    <i
                                      class="icon-0-icons-dark-download btn-cursor"
                                      style="font-size: 20px;"
                                    />
                                  </span>
                                </div>
                              </div>
                            </span>
                            <!-- Login -->
                          </b-media>
                        </div>
                        <div v-else>
                          {{ $t('generic.notAvailable') }}
                        </div>
                      </dd>
                    </dl>
                    <dl class="row">
                      <dt class="col-sm-3">
                        <b>{{ $t('member.form.label.registrationFederation') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        {{ member.registration_in_federation ? member.registration_in_federation : $t('generic.doesNotApply') }}
                      </dd>
                    </dl>
                    <dl
                      v-if="member.id_fifa"
                      class="row"
                    >
                      <dt class="col-sm-3">
                        <b>{{ $t('member.form.label.idFifa') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        {{ member.id_fifa }}
                      </dd>
                    </dl>
                    <dl class="row">
                      <dt class="col-sm-3">
                        <b>{{ $t('member.form.label.dateAdmission') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        <template v-if="member.date_of_admission">
                          {{ member.date_of_admission | formatDate }}
                        </template>
                        <template v-else>
                          {{ $t('generic.doesNotApply') }}
                        </template>
                      </dd>
                    </dl>
                    <dl
                      v-if="member.gender != null"
                      class="row"
                    >
                      <dt class="col-sm-3">
                        <b>{{ $t('member.form.label.gender') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        {{ member.gender.name }}
                      </dd>
                    </dl>
                    <dl
                      v-if="member.jersey_number != null"
                      class="row"
                    >
                      <dt class="col-sm-3">
                        <b>{{ $t('generic.no') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        {{ member.jersey_number }}
                      </dd>
                    </dl>
                    <dl
                      v-else-if="isObject(member.staff_job_position) && member.staff_job_position_hash != null"
                      class="row"
                    >
                      <dt class="col-sm-3">
                        <b>{{ $t('generic.jobPosition') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        {{ member.staff_job_position.name }}
                      </dd>
                    </dl>
                    <dl
                      v-else-if="member.another_job != null"
                      class="row"
                    >
                      <dt class="col-sm-3">
                        <b>{{ $t('generic.jobPosition') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        {{ member.another_job }}
                      </dd>
                    </dl>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          <!--/ Description lists horizontal -->
          </div>
          <div v-if="optionActiveD == 2">

            <b-row
              v-if="checkEdit()"
              cols="12"
            >
              <b-col align-self="end">
                <span
                  v-if="!showForm2"
                  class="float-right ml-1"
                >
                  {{ $t( 'buttons.edit') }}
                  <b-button
                    class="btn-icon rounded-circle"
                    variant="secondary"
                    @click="showForm2 = true"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </span>
              </b-col>
            </b-row>
            <!-- End buttons -->

            <!-- Component edit -->
            <b-row
              v-if="showForm2"
              class="pl-1 pr-1"
            >
              <edit-member-form2
                :option="member.member_type.key"
                :member="member"
                :countries="countries"
                :states="states"
                :education-levels="educationLevels"
                :section-coaching-staff="sectionCoachingStaff"
                :section-player="sectionPlayer"
                :gender-select="genderSelect"
                :color-primary-btn="colorPrimaryBtn"
                @hidden-form="handleUpdate"
              />
            </b-row>
            <!-- End component edit -->

            <b-row
              v-if="!showForm2"
              class="match-height"
            >
              <b-col
                sm="12"
                md="5"
                lg="5"
              >
                <upload-member-image
                  :full-name="member.full_name"
                  :age="member.age"
                  :avatar="member.avatar_file"
                  :job="member.staff_job_position ? member.staff_job_position.name : null"
                  :number="member.jersey_number"
                  :another-job="member.another_job"
                  :player-status="member.player_status"
                  :ceding-club="member.contractual_condition ? member.contractual_condition.transfer.ceding_club : ''"
                />
              </b-col>
              <!-- Description lists horizontal -->
              <b-col
                sm="12"
                md="7"
                lg="7"
              >
                <b-card no-body>
                  <b-card-body>
                    <dl class="row">
                      <dt class="col-sm-3">
                        <b>{{ $t('generic.name') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        {{ member.name ? member.name : $t('generic.doesNotApply') }}
                      </dd>
                    </dl>
                    <dl class="row">
                      <dt class="col-sm-3">
                        <b>{{ $t('member.form.label.firstName') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        {{ member.first_name ? member.first_name : $t('generic.doesNotApply') }}
                      </dd>
                    </dl>
                    <dl class="row">
                      <dt class="col-sm-3">
                        <b>{{ $t('member.form.label.lastName') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        {{ member.last_name ? member.last_name : $t('generic.doesNotApply') }}
                      </dd>
                    </dl>
                    <!-- <dl
                    v-if="member.gender"
                    class="row"
                  >
                    <dt class="col-sm-3">
                      <b>{{ $t('member.form.label.gender') }}</b>
                    </dt>
                    <dd class="col-sm-9">
                      {{ member.gender }}
                    </dd>
                  </dl> -->
                    <dl class="row">
                      <dt class="col-sm-3">
                        <b>{{ $t('member.form.label.birthday') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        <template v-if="member.birthday">
                          {{ member.birthday | formatDate }}
                        </template>
                        <template v-else>
                          {{ $t('generic.doesNotApply') }}
                        </template>
                      </dd>
                    </dl>
                    <dl class="row">
                      <dt class="col-sm-3">
                        <b>{{ $t('member.form.label.placeBirth') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        <b> {{ $t('generic.state') }}</b> {{ member.state_birth ? member.state_birth.name : $t('generic.doesNotApply') }},
                        <b>{{ $t('generic.country') }}</b> {{ member.country_birth ? member.country_birth.name : $t('generic.doesNotApply') }}.
                      </dd>
                    </dl>
                    <dl class="row">
                      <dt class="col-sm-3">
                        <b>{{ $t('member.form.label.lastDegreeStudies') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        {{ member.education_level ? member.education_level.name : $t('generic.doesNotApply') }}
                      </dd>
                    </dl>
                    <dl class="row">
                      <dt class="col-sm-3">
                        <b>{{ $t('member.form.label.currentAddress') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        {{ member.address ? member.address.address : $t('generic.doesNotApply') }},
                        <b>{{ $t("generic.cp") }}</b> {{ member.address.zip_code ? member.address.zip_code : $t('generic.doesNotApply') }},
                        <b> {{ $t('generic.state') }}</b> {{ member.address.state ? member.address.state.name : $t('generic.doesNotApply') }},
                        <b>{{ $t('generic.country') }}</b> {{ member.address.country ? member.address.country.labelables[0].label : $t('generic.doesNotApply') }}.
                      </dd>
                    </dl>
                    <dl class="row">
                      <dt class="col-sm-3">
                        <b>{{ $t('member.form.label.email') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        {{ member.email ? member.email : $t('generic.doesNotApply') }}
                      </dd>
                    </dl>
                    <dl class="row">
                      <dt class="col-sm-3">
                        <b>{{ $t('member.form.label.cellPhone') }}</b>
                      </dt>
                      <dd class="col-sm-9">
                        {{ member.cell_phone ? member.cell_phone : $t('generic.doesNotApply') }}
                      </dd>
                    </dl>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </div>
          <!-- Condiciones contractuales -->
          <div v-if="optionActiveD == 3">
            <div v-if="showConditions">
              <details-contractual-conditions
                :member-id="member.hash"
                :check-permission="checkPermission"
                :countries="countries"
              />
            <!-- :cities="cities" -->
            </div>
          </div>
          <div v-if="optionActiveD == 4">
            <!-- Buttons Edit and cancel -->
            <b-row
              v-if="checkEdit()"
              cols="12"
            >
              <b-col align-self="end">
                <span
                  v-if="!showForm3"
                  class="float-right ml-1"
                >
                  {{ $t( 'buttons.edit') }}
                  <b-button
                    class="btn-icon rounded-circle"
                    variant="secondary"
                    @click="showForm3 = true"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </span>
              </b-col>
            </b-row>

            <!-- Component edit -->
            <b-row
              v-if="showForm3"
              class="pl-1 pr-1"
            >
              <!-- :cities="cities" -->
              <edit-member-form3
                :option="member.member_type.key"
                :member="member"
                :countries="countries"
                :catalog-nationalities="catalogNationalities"
                :section-coaching-staff="sectionCoachingStaff"
                :section-player="sectionPlayer"
                :gender-select="genderSelect"
                :color-primary-btn="colorPrimaryBtn"
                @hidden-form="handleUpdate"
              />
            </b-row>
            <!-- End component edit -->
            <b-row
              v-if="!showForm3"
              class="match-height"
            >
              <b-col
                sm="12"
                md="5"
                lg="5"
              >
                <upload-member-image
                  :full-name="member.full_name"
                  :age="member.age"
                  :avatar="member.avatar_file"
                  :job="member.staff_job_position ? member.staff_job_position.name : null"
                  :number="member.jersey_number"
                  :another-job="member.another_job"
                  :player-status="member.player_status"
                  :ceding-club="member.contractual_condition ? member.contractual_condition.transfer.ceding_club : ''"
                />
              </b-col>
              <b-col
                sm="12"
                md="7"
                lg="7"
                align-h="center"
              >
                <!-- toggle button -->
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-toggle.collapse-Identificacion
                  class="w-50"
                  variant="outline-primary"
                >
                  <b>{{ $t('member.form.label.identification') }}</b>
                </b-button>

                <b-collapse
                  id="collapse-Identificacion"
                  visible
                  class="mt-2"
                >

                  <!-- Form identification -->
                  <b-row align-h="center">
                    <b-col>
                      <b-media class="mb-2">
                        <template #aside>
                          <div v-if="isObject(member.file_identification) && member.file_identification.mime_type != 'application/pdf'">
                            <image-viewer
                              :url-thumb="member.file_identification.url_thumb"
                              :document-id="member.path_file_identification_media_file_hash"
                              permission-show="VIEW_IDENTIFICATION_FILE_MEMBERS"
                              permission-download="DOWNLOAD_IDENTIFICATION_FILE_MEMBERS"
                            />
                          </div>
                          <div v-else>
                            <ViewerDocumentModal
                              :title="member.file_identification ? member.file_identification.title : ''"
                              :url-thumb="member.file_identification ? member.file_identification.url_thumb : null"
                              :document-id="member.path_file_identification_media_file_hash"
                              permission-show="VIEW_IDENTIFICATION_FILE_MEMBERS"
                              permission-download="DOWNLOAD_IDENTIFICATION_FILE_MEMBERS"
                            />
                          </div>
                        </template>
                        <h6> <b>{{ $t('member.form.label.idNumber') }}</b> </h6>
                        <label> {{ member.id_number }} </label>
                        <div v-if="member.path_file_identification_media_file_hash && isObject(member.file_identification) && checkPermission(['DOWNLOAD_IDENTIFICATION_FILE_MEMBERS', 'ALLOW_ALL'])">
                          <span
                            @click="verifyPermissionAndPassword('DOWNLOAD_IDENTIFICATION_FILE_MEMBERS', member.path_file_identification_media_file_hash, member.file_identification.title)"
                          >
                            <i class="icon-0-icons-dark-download btn-cursor" />
                          </span>
                        </div>
                      </b-media>

                      <!-- BirthCertificate -->
                      <b-media class="mb-2">
                        <template #aside>
                          <div v-if="isObject(member.file_birth_certificate) && member.file_birth_certificate.mime_type != 'application/pdf'">
                            <image-viewer
                              :document-id="member.path_file_birth_certificate_media_file_hash"
                              :url-thumb="member.file_birth_certificate.url_thumb"
                              permission-show="VIEW_BIRTH_CERTIFICATE_FILE_MEMBERS"
                              permission-download="DOWNLOAD_BIRTH_CERTIFICATE_FILE_MEMBERS"
                            />
                          </div>
                          <div v-else>
                            <ViewerDocumentModal
                              :title="member.file_birth_certificate ? member.file_birth_certificate.title : ''"
                              :url-thumb="member.file_birth_certificate ? member.file_birth_certificate.url_thumb : null"
                              :document-id="member.path_file_birth_certificate_media_file_hash"
                              permission-show="VIEW_BIRTH_CERTIFICATE_FILE_MEMBERS"
                              permission-download="DOWNLOAD_BIRTH_CERTIFICATE_FILE_MEMBERS"
                            />
                          </div>
                        </template>
                        <h6> <b>{{ $t('member.form.label.birthCertificate') }}</b> </h6>
                        <div v-if="member.path_file_birth_certificate_media_file_hash && isObject(member.file_birth_certificate) && checkPermission(['DOWNLOAD_BIRTH_CERTIFICATE_FILE_MEMBERS', 'ALLOW_ALL'])">
                          <span
                            @click="verifyPermissionAndPassword('DOWNLOAD_BIRTH_CERTIFICATE_FILE_MEMBERS', member.path_file_birth_certificate_media_file_hash, member.file_birth_certificate.title)"
                          >
                            <i class="icon-0-icons-dark-download btn-cursor" />
                          </span>
                        </div>
                      </b-media>

                      <!-- title or licence -->
                      <b-media
                        v-if="member.member_type.key === sectionCoachingStaff"
                        class="mb-2"
                      >
                        <template #aside>
                          <div v-if="isObject(member.file_title_or_licence) && member.file_title_or_licence.mime_type != 'application/pdf'">
                            <image-viewer
                              :document-id="member.path_file_title_or_licence_media_file_hash"
                              :url-thumb="member.file_title_or_licence.url_thumb"
                              permission-show="VIEW_TITLE_OR_LICENCE_FILE_MEMBERS"
                              permission-download="DOWNLOAD_TITLE_OR_LICENCE_FILE_MEMBERS"
                            />
                          </div>
                          <div v-else>
                            <ViewerDocumentModal
                              :title="member.file_title_or_licence ? member.file_title_or_licence.title : ''"
                              :url-thumb="member.file_title_or_licence ? member.file_title_or_licence.url_thumb : null"
                              :document-id="member.path_file_title_or_licence_media_file_hash"
                              permission-show="VIEW_TITLE_OR_LICENCE_FILE_MEMBERS"
                              permission-download="DOWNLOAD_TITLE_OR_LICENCE_FILE_MEMBERS"
                            />
                          </div>
                        </template>
                        <h6> <b>{{ $t('title-or-licence') }}</b> </h6>
                        <label> {{ member.title_or_licence }} </label>
                        <div v-if="member.path_file_title_or_licence_media_file_hash && isObject(member.file_title_or_licence) && checkPermission(['DOWNLOAD_TITLE_OR_LICENCE_FILE_MEMBERS', 'ALLOW_ALL'])">
                          <span
                            @click="verifyPermissionAndPassword('DOWNLOAD_TITLE_OR_LICENCE_FILE_MEMBERS', member.path_file_title_or_licence_media_file_hash, member.file_title_or_licence.title)"
                          >
                            <i class="icon-0-icons-dark-download btn-cursor" />
                          </span>
                        </div>
                      </b-media>
                    </b-col>
                  </b-row>
                </b-collapse>

                <!-- Nacionalidad -->
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-toggle.collapse-nationality
                  class="w-50 mt-1"
                  variant="outline-primary"
                >
                  <b>{{ $t('member.form.label.nacionality') }}</b>
                </b-button>

                <b-collapse
                  id="collapse-nationality"
                  visible
                  class="mt-2"
                >

                  <!-- Form identification -->
                  <b-row align-h="center">
                    <b-col>
                      <b-media
                        v-for="item in member.nationalities"
                        :key="item.hash"
                      >
                        <template #aside>
                          <div v-if="isObject(item.file_passport) && item.file_passport.mime_type != 'application/pdf'">
                            <image-viewer
                              :document-id="item.path_file_passport_media_file_hash"
                              :url-thumb="item.file_passport.url_thumb"
                              permission-show="VIEW_PASSPORT_FILE_MEMBERS"
                              permission-download="DOWNLOAD_PASSPORT_FILE_MEMBERS"
                            />
                          </div>
                          <div v-else>
                            <ViewerDocumentModal
                              :title="item.file_passport ? item.file_passport.title : ''"
                              :url-thumb="item.file_passport ? item.file_passport.url_thumb : null"
                              :document-id="item.path_file_passport_media_file_hash"
                              permission-show="VIEW_PASSPORT_FILE_MEMBERS"
                              permission-download="DOWNLOAD_PASSPORT_FILE_MEMBERS"
                            />
                          </div>
                        </template>
                        <!-- <h6></h6> -->
                        <h6 class="font-weight-bolder">
                          {{ $t('member.form.label.nacionality') }}
                        </h6>
                        <p v-if="item.nationality">
                          {{ item.nationality.name }}
                        </p>
                        <h6 class="font-weight-bolder">
                          {{ $t('member.form.label.passport') }}
                        </h6> <label>{{ item.passport_number }}</label>
                        <h6 class="font-weight-bolder">
                          {{ $t('member.form.label.expiration') }}
                        </h6> <label>{{ item.passport_expiration }}</label> <br>
                        <div v-if="item.path_file_passport_media_file_hash && checkPermission(['DOWNLOAD_PASSPORT_FILE_MEMBERS', 'ALLOW_ALL'])">
                          <span
                            v-if="isObject(item.file_passport)"
                            @click="verifyPermissionAndPassword('DOWNLOAD_PASSPORT_FILE_MEMBERS', item.path_file_passport_media_file_hash, item.file_passport.title)"
                          >
                            <i class="icon-0-icons-dark-download btn-cursor" />
                          </span>
                        </div>
                        <br><br>
                      </b-media>
                    </b-col>
                  </b-row>
                </b-collapse>

                <!-- Visa -->
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-toggle.collapse-visa
                  class="w-50 mt-1"
                  variant="outline-primary"
                >
                  <b>{{ $t('member.form.label.visa') }}</b>
                </b-button>

                <b-collapse
                  id="collapse-visa"
                  visible
                  class="mt-2"
                >

                  <!-- Form identification -->
                  <b-row align-h="center">
                    <b-col>
                      <b-media
                        v-for="item in member.visas"
                        :key="item.hash"
                      >
                        <template #aside>
                          <div v-if="isObject(item.file_visa) && item.file_visa.mime_type != 'application/pdf'">
                            <image-viewer
                              :document-id="item.path_file_visa_media_file_hash"
                              :url-thumb="item.file_visa.url_thumb"
                              permission-show="VIEW_VISA_FILE_MEMBERS"
                              permission-download="DOWNLOAD_VISA_FILE_MEMBERS"
                            />
                          </div>
                          <div v-else>
                            <ViewerDocumentModal
                              :title="item.file_visa ? item.file_visa.title : ''"
                              :url-thumb="item.file_visa ? item.file_visa.url_thumb : null"
                              :document-id="item.path_file_visa_media_file_hash"
                              permission-show="VIEW_VISA_FILE_MEMBERS"
                              permission-download="DOWNLOAD_VISA_FILE_MEMBERS"
                            />
                          </div>
                        </template>
                        <h6 class="font-weight-bolder">
                          {{ $t('member.form.label.issuedBy') }}
                        </h6>
                        <p v-if="item.expedition_country">
                          {{ item.expedition_country.name }}
                        </p>
                        <h6 class="font-weight-bolder">
                          {{ $t('member.form.label.visaNumber') }}
                        </h6> <label>{{ item.visa_number }}</label>
                        <h6 class="font-weight-bolder">
                          {{ $t('member.form.label.expiration') }}
                        </h6> <label>{{ item.visa_expiration }}</label> <br>
                        <div v-if="item.path_file_visa_media_file_hash != null && checkPermission(['DOWNLOAD_VISA_FILE_MEMBERS', 'ALLOW_ALL'])">
                          <span
                            v-if="isObject(item.file_visa)"
                            @click="verifyPermissionAndPassword('DOWNLOAD_VISA_FILE_MEMBERS', item.path_file_visa_media_file_hash, item.file_visa.title)"
                          >
                            <i class="icon-0-icons-dark-download btn-cursor" />
                          </span><br><br>
                        </div>
                      </b-media>
                    </b-col>
                  </b-row>
                </b-collapse>
              </b-col>
            </b-row>
          </div>
        </b-col>

        <login-modal
          :permission="validPermission"
          :file-id="fileId"
          :file-title="fileTitle"
          @change="toAccess"
        />
        <!-- <pdf-viewer-modal /> -->
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import OverlayButton from '@/views/partials/OverlayButton.vue'
import checkPermission from '@/auth/permissions'
// eslint-disable-next-line import/no-cycle
import { isObject, downloadFile } from '@core/utils/utils'
import {
  required, password, min,
} from '@validations'
import DetailsContractualConditions from './contractual-conditions/details/Detail.vue'
import EditMemberForm1 from './EditMemberForm1.vue'
import EditMemberForm2 from './EditMemberForm2.vue'
import EditMemberForm3 from './EditMemberForm3.vue'
import UploadMemberImage from './UploadMemberImage.vue'
import ImageViewer from './ImageViewer.vue'
import ViewerDocumentModal from './ViewerDocumentModal.vue'
import LoginModal from './LoginModal.vue'
// import Thumb from './Thumb.vue'

export default {
  components: {
    // ValidationProvider,
    // ValidationObserver,
    DetailsContractualConditions,
    EditMemberForm1,
    EditMemberForm2,
    EditMemberForm3,
    UploadMemberImage,
    // OverlayButton,
    ImageViewer,
    ViewerDocumentModal,
    LoginModal,
    // Thumb,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      validPermission: null,
      fileId: null,
      fileTitle: null,
      showFile: false,
      // member: null,
      isLoading: false,
      showConditions: false,
      isLoadingAccess: false,
      showModal: null,
      required, // validation rules
      password, // validation rules
      min, // validation rules
      form: {
        password: null,
      },
      memberType: {
        key: '',
      },
      optionActiveD: '',
      sectionName: '',
      showForm1: false,
      showForm2: false,
      showForm3: false,
      sectionPlayer: 'PLAYER',
      sectionCoachingStaff: 'COACHING_STAFF',
      // catalogNationalities: [],
      sectionsInTab: [{
        id: 1,
        name: this.$t('member.wizard.title.one'),
      }, {
        id: 2,
        name: this.$t('member.wizard.title.two'),
      }, {
        id: 3,
        name: this.$t('member.wizard.title-three'),
      }, {
        id: 4,
        name: this.$t('member.wizard.title.three'),
      }],
      tempQuery: {
        filtersObj: {},
        paramsObj: {
          included: 'team,club,category,memberType,playerStatus',
        },
      },
      genderSelect: null,
      showIdentification: false,
    }
  },
  computed: {
    ...mapGetters({
      member: 'memberStore/member',
      clubs: 'clubList',
      countries: 'countries',
      states: 'states',
      categories: 'catalogs/categories',
      educationLevels: 'catalogs/educationLevels',
      staffJobPositions: 'catalogs/staffJobPositions',
      playerStatuses: 'catalogs/playerStatuses',
      genders: 'catalogs/genders',
      teams: 'teamStore/teamList',
      // clubInfo: 'clubInfo',
      jerseyNumbers: 'teamStore/jerseyNumbers',
      emailUserAuth: 'email',
      colorPrimaryBtn: 'colorPrimaryBtn',
      catalogNationalities: 'catalogs/nationalities',
    }),
  },
  watch: {
    '$i18n.locale': function i18nlocale() {
      this.fetchCountries()
      this.fetchPlayerStatuses()
    },
  },
  mounted() {
    this.$store.commit('memberStore/resetState')
    this.optionActiveD = 1
    if (this.$route.params.id == null) {
      this.$router.push({ name: 'member' })
    }

    // this.gender_hash = this.member.gender_hash || null

    this.sectionName = this.$t('member.wizard.title.one')
    this.catalogs()
    this.showConditions = false
  },
  beforeDestroy() {
    this.$store.commit('memberStore/resetState')
  },
  methods: {
    downloadFile,
    checkPermission,
    isObject,
    ...mapActions({
      fetchMemberById: 'memberStore/fetchMemberById',
      validateViewPemissionByPassword: 'memberStore/validateViewPemissionByPassword',
      // getMembersData: 'memberStore/getMembers',
      fetchCountries: 'fetchCountries',
      fetchClubs: 'fetchClubs',
      fetchGenders: 'catalogs/fetchGenders',
      fetchPlayerStatuses: 'catalogs/fetchPlayerStatuses',
      fetchStaffJobPositions: 'catalogs/fetchStaffJobPositions',
      fetchEducationLevels: 'catalogs/fetchEducationLevels',
      fetchNationalities: 'catalogs/fetchNationalities',
      fetchTeamByIdClub: 'teamStore/fetchTeamByIdClub',
      mediaFileShow: 'mediaFile/show',
      fetchMembersTypes: 'catalogs/fetchMembersTypes',
    }),
    assignRouteY(pRoute) {
      if (pRoute === 1) {
        this.sectionName = this.$t('member.wizard.title.one')
        this.optionActiveD = 1
        this.showConditions = false
        this.showFile = false
      }
      if (pRoute === 2) {
        this.sectionName = this.$t('member.wizard.title.two')
        this.optionActiveD = 2
        this.showConditions = false
        this.showFile = false
      }
      if (pRoute === 3) {
        this.showFile = false
        this.sectionName = this.$t('member.wizard.title-three')
        // TODO: Comentar
        // this.optionActiveD = 3
        // this.showConditions = true
        if (!this.checkPermission(['VIEW_MEMBERS_CONTRACTUAL_CONDITIONS', 'ALLOW_ALL'])) {
          this.showError(this.$t('access-denied'))
          return
        }
        // TODO: descomentar
        this.validPermission = 'VIEW_MEMBERS_CONTRACTUAL_CONDITIONS'
        // this.validPermission = 'EDIT_MEMBERS_PLAYERS'
        this.$root.$emit('bv::show::modal', 'modal-login')
      }
      if (pRoute === 4) {
        this.sectionName = this.$t('member.wizard.title.three')
        this.optionActiveD = 4
        this.showConditions = false
        this.showFile = false
      }
    },
    verifyPermissionAndPassword(permission, fileId, fileTitle) {
      // this.validPermission = 'VIEW_CONTRACT_FILE_MEMBERS'
      this.validPermission = permission
      this.fileId = fileId
      this.fileTitle = fileTitle || null
      this.$root.$emit('bv::show::modal', 'modal-login')
      // const routeData = this.$router.resolve({ name: 'document-member', query: { id: idContract } })
      // window.open(routeData.href, '_blank')
    },
    goEditMember() {
      this.$router.push({ name: 'edit-member' })
    },
    async catalogs() {
      this.isLoading = true
      await this.fetchCountries()
      await this.fetchPlayerStatuses()
      await this.fetchStaffJobPositions()
      await this.fetchGenders()
      await this.fetchEducationLevels()
      this.tempQuery.filtersObj.hash = this.$route.params.id
      await this.fetchMemberById(this.$route.params.id).then(() => {
      }).catch(error => {
        this.responseCatch(error)
      })
      this.gender_hash = this.member.gender_hash || null
      await this.fetchNationalities().then(() => {
      }).catch(error => {
        this.responseCatch(error)
      })
      await this.fetchMembersTypes().then(response => {
        const { data } = response.data
        let resultGender = null
        resultGender = data.find(item => item.gender_hash === Number(this.gender_hash))
        if (resultGender != null && resultGender !== undefined) {
          this.genderSelect = resultGender.labelables[0].label
        }
      })
      this.isLoading = false
    },
    handleUpdate() {
      this.showForm1 = false
      this.showForm2 = false
      this.showForm3 = false
      this.fetchMemberById(this.member.hash)
    },
    async toAccess(permissionData) {
      if (permissionData.permission === 'VIEW_MEMBERS_CONTRACTUAL_CONDITIONS') {
        this.optionActiveD = 3
        this.showConditions = true
      }
      if (permissionData.permission === 'DOWNLOAD_CONTRACT_FILE_MEMBERS') {
        this.handleDownloadFile(permissionData.fileId, permissionData.fileTitle)
      }
      if (permissionData.permission === 'DOWNLOAD_IDENTIFICATION_FILE_MEMBERS') {
        this.handleDownloadFile(permissionData.fileId, permissionData.fileTitle)
      }
      if (permissionData.permission === 'DOWNLOAD_BIRTH_CERTIFICATE_FILE_MEMBERS') {
        this.handleDownloadFile(permissionData.fileId, permissionData.fileTitle)
      }
      if (permissionData.permission === 'DOWNLOAD_PASSPORT_FILE_MEMBERS') {
        this.handleDownloadFile(permissionData.fileId, permissionData.fileTitle)
      }

      if (permissionData.permission === 'DOWNLOAD_VISA_FILE_MEMBERS') {
        this.handleDownloadFile(permissionData.fileId, permissionData.fileTitle)
      }

      if (permissionData.permission === 'DOWNLOAD_TITLE_OR_LICENCE_FILE_MEMBERS') {
        this.handleDownloadFile(permissionData.fileId, permissionData.fileTitle)
      }
    },
    async handleDownloadFile(fileHash, title) {
      this.isLoading = true
      await this.mediaFileShow(fileHash).then(response => {
        const { data: dataDocument } = response
        this.downloadFile(dataDocument, title)
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.responseCatch(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
    checkEdit() {
      this.memberType = this.member.member_type || this.memberType
      if (this.memberType.key === this.sectionPlayer) {
        return this.checkPermission(['EDIT_MEMBERS_PLAYERS', 'ALLOW_ALL'])
      }
      if (this.memberType.key === this.sectionCoachingStaff) {
        return this.checkPermission(['EDIT_MEMBERS_COACHING_STAFF', 'ALLOW_ALL'])
      }
      return false
    },
  },
}
</script>
<style scoped>
.btn-cursor {
  cursor: pointer;
}
.color-icon {
  color: #59637f;
}
</style>
