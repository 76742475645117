<template>
  <div>
    <span>
      <validation-observer ref="form2Update">
        <b-form>
          <head-title-wizard
            :section-player="sectionPlayer"
            :section-coaching-staff="sectionCoachingStaff"
            :option="option"
            :gender="genderSelect ? genderSelect : '' "
          />
          <b-row>
            <b-col
              sm="12"
              md="12"
              lg="12"
            >
              <b-row align-h="center">
                <b-col>
                  <div class="text-center">
                    <upload-image
                      :tooltip-text="'generic.allowedFormat'"
                      :button-text="'generic.uploadPhoto'"
                      :avatar-icon="'icon-0-icons-dark-avatar'"
                      :image-size="'10rem'"
                      :apply-b-link="true"
                      :disabled="member.club_hash == null"
                      :path-file-avatar="member.avatar_file ? member.avatar_file.url_thumb : ''"
                      @intance-file-x="fileValidation($event, 'avatar_file')"
                    />
                    <br>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>

            <b-col
              sm="12"
              md="6"
              lg="6"
            >
              <b-form-group>
                <label>{{ $t('member.form.label.name') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required|max:254|nameRegex"
                  name="name"
                >
                  <b-form-input
                    v-model="member.name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('member.form.placeholder.enterName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="6"
            >
              <b-form-group>
                <label>{{ $t('member.form.label.firstName') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required|max:254|nameRegex"
                  name="firstName"
                >

                  <b-form-input
                    v-model="member.first_name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('member.form.placeholder.enterFirstName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="6"
            >
              <b-form-group>
                <label>{{ $t('member.form.label.lastName') }}</label>
                <b-form-input
                  v-model="member.last_name"
                  type="text"
                  :placeholder="$t('member.form.placeholder.enterLastName')"
                />
              </b-form-group>
            </b-col>

            <!-- Fecha de nacimiento -->
            <b-col
              sm="12"
              md="3"
              lg="3"
            >
              <b-form-group>
                <label>{{ $t('member.form.label.birthday') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="birthday"
                >
                  <b-form-datepicker
                    v-model="member.birthday"
                    :locale="$i18n.locale"
                    :max="new Date()"
                    class="form-control"
                    :placeholder="$t('member.form.placeholder.selectDate')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="3"
              lg="3"
            >
              <b-form-group>
                <label>{{ $t('generic.nameLegalResponsible') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  :rules="validAge >= 18 ? '' : 'required'"
                  name="name"
                >
                  <b-form-input
                    v-model="member.holder_name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('member.form.placeholder.enterName')"
                    :disabled="validAge >= 18"
                  />
                  <small class="text-muted"> {{ $t('generic.onlyMinors') }}</small> <br>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="3"
              lg="3"
            >
              <b-form-group>
                <label>{{ $t('generic.countryOfBirth') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="countryBirth"
                >
                  <v-select
                    id="club-country-id"
                    v-model="member.country_birth_hash"
                    label="name"
                    :options="countries"
                    :reduce="country => country.id"
                    :placeholder="$t('generic.selectCountry')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <div slot="no-options">
                      {{ $t('generic.noRecordsFound') }}
                    </div>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="3"
              lg="3"
            >
              <b-form-group>
                <label>{{ $t('generic.stateOfBirth') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="stateBirth"
                >
                  <v-select
                    id="club-state-id"
                    v-model="member.state_birth_hash"
                    label="name"
                    :options="memberBirthStates"
                    :reduce="city => city.hash"
                    :placeholder="$t('generic.selectState')"
                    :state="errors.length > 0 ? false : null"
                    :disabled="member.country_birth_hash == null"
                  >
                    <div slot="no-options">
                      {{ $t('generic.noRecordsFound') }}
                    </div>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="6"
            >
              <b-form-group>
                <label>{{ $t('member.form.label.lastDegreeStudies') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="lastDegreeStudies"
                >

                  <v-select
                    id="studies-id"
                    v-model="member.education_level_hash"
                    label="name"
                    :options="educationLevels"
                    :reduce="level => level.id"
                    :placeholder="$t('member.form.placeholder.selectStudies')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <div slot="no-options">
                      {{ $t('generic.noRecordsFound') }}
                    </div>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="6"
            >
              <b-form-group>
                <label>{{ $t('member.form.label.email') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required|email"
                  name="email"
                >
                  <b-form-input
                    v-model="member.email"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('member.form.placeholder.enterEmail')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              sm="12"
              md="6"
              lg="6"
            >
              <b-form-group>
                <label>{{ $t('member.form.label.cellPhone') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="cellPhone"
                >
                  <VuePhoneNumberInput
                    v-model="member.cell_phone"
                    valid-color="#98a1b3"
                    error-color="#cc296a"
                    color="#cc296a"
                    default-country-code="MX"
                    :translations="{
                      countrySelectorLabel: $t('generic.telephonePrefix'),
                      countrySelectorError: $t('generic.chooseCountry'),
                      phoneNumberLabel: $t('member.form.placeholder.enterCellPhone'),
                      example: $t('generic.example')
                    }"
                    @update="resultsCellPhone = $event"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="8"
              class="mt-18"
            >
              <span>{{ $t('member.form.label.currentAddress') }}</span>
              <hr>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="6"
            >
              <b-form-group>
                <label>{{ $t("accountSettings.address") }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required|max:255|addressRegex"
                  name="address"
                >
                  <b-form-input
                    v-model="member.address.address"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('member.form.placeholder.enterAddressFull')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="6"
            >
              <b-form-group>
                <label for="postal-code">
                  {{ $t("accountSettings.postalCode") }}
                  <span class="text-danger">*</span>
                </label>
                <validation-provider
                  #default="{ errors }"
                  rules="required|max:255|zipRegex"
                  name="postalCode"
                >
                  <b-form-input
                    id="postal-code"
                    v-model="member.address.zip_code"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('accountSettings.postalCode')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="6"
            >
              <b-form-group>
                <label for="club-country-id">{{ $t('generic.country') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="country"
                >
                  <v-select
                    id="club-country-id"
                    v-model="member.address.country_hash"
                    label="name"
                    :options="countries"
                    :reduce="country => country.id"
                    :placeholder="$t('generic.selectCountry')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <div slot="no-options">
                      {{ $t('generic.noRecordsFound') }}
                    </div>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="6"
            >
              <b-form-group>
                <label for="club-state-id">{{ $t('generic.state') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="state"
                >
                  <v-select
                    id="club-state-id"
                    v-model="member.address.state_hash"
                    label="name"
                    :options="memberAddressStates"
                    :reduce="city => city.hash"
                    :disabled="member.address.country_hash == null"
                    :placeholder="$t('generic.selectState')"
                  >
                    <div slot="no-options">
                      {{ $t('generic.noRecordsFound') }}
                    </div>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>
      <div class="btn-footer-modal">

        <!-- Buttons cancel -->
        <b-button
          variant="secondary"
          pill
          style="margin-left: 17px;"
          @click="$emit('hidden-form')"
        >
          {{ $t('buttons.cancel') }}
        </b-button>

        <!-- button save -->
        <overlay-button :loading="isLoadingSave">
          <b-button
            variant="primary"
            pill
            style="margin-left: 17px;"
            :style="colorPrimaryBtn"
            @click="onUpdateMember"
          >
            {{ $t("buttons.save") }}
          </b-button>
        </overlay-button>

      </div>
      <div class="float-right">
        <b-button
          v-if="checkDeleted"
          variant="outline-secondary"
          pill
          style="margin-left: 17px;"
          class="d-none d-sm-none d-md-block"
          @click="onRemoveMember"
        >
          <i class="icon-0-icons-dark-trash-can mr-25" />
          <span class="align-middle">{{ $t('buttons.delete') }}</span>
        </b-button>
      </div>
      <b-button
        v-if="checkDeleted"
        class="d-block d-sm-block d-md-none w-100 mt-2"
        variant="outline-secondary"
        pill
        @click="onRemoveMember"
      >
        <i class="icon-0-icons-dark-trash-can mr-25" />
        <span class="align-middle">{{ $t('buttons.delete') }}</span>
      </b-button>
    </span></div>
</template>
<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { validatorFileSize, validatorFileType } from '@core/utils/validations/validators'
import OverlayButton from '@/views/partials/OverlayButton.vue'
import { mapActions } from 'vuex'
import checkPermission from '@/auth/permissions'
import { hasKeyObject, isObject } from '@core/utils/utils'
import { required, email } from '@validations'
import VuePhoneNumberInput from 'vue-phone-number-input'
import Ripple from 'vue-ripple-directive'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import UploadImage from '../partials/UploadImageAvatar.vue'
import HeadTitleWizard from './components/HeadTitleWizard.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
    UploadImage,
    vSelect,
    OverlayButton,
    HeadTitleWizard,
  },
  directives: {
    Ripple,
  },
  props: {
    member: {
      type: Object,
      default: null,
    },
    countries: {
      type: Array,
      default: null,
    },
    educationLevels: {
      type: Array,
      default: null,
    },
    sectionPlayer: {
      type: String,
      default: null,
    },
    sectionCoachingStaff: {
      type: String,
      default: null,
    },
    option: {
      type: String,
      default: null,
    },
    colorPrimaryBtn: {
      type: Object,
      default: null,
    },
    genderSelect: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      validAge: 20,
      resultsCellPhone: null,
      isLoadingSave: false,
      required,
      email,
      idCountry: null,
      memberBirthStates: [],
      memberAddressStates: [],
      filters: {
        page: 1,
        perPage: 10,
      },
    }
  },
  computed: {
    checkDeleted() {
      const { key } = this.member.member_type
      if (key === this.sectionPlayer) {
        return this.checkPermission(['DELETE_MEMBERS_PLAYERS', 'ALLOW_ALL'])
      }
      if (key === this.sectionCoachingStaff) {
        return this.checkPermission(['DELETE_MEMBERS_COACHING_STAFF', 'ALLOW_ALL'])
      }
      return false
    },
  },
  watch: {
    'member.address.country_hash': function idCountry(pCountry) {
      if (pCountry !== null) {
        // this.fetchCitiesByCountryId(pCountry)
        this.getMemberAddressStates(pCountry)
      }
      this.member.address.state_hash = null
    },
    'member.country_birth_hash': function memberCountryBirthID(ctry) {
      if (ctry !== null) {
        this.getMemberBirthStates(ctry)
        // this.fetchCitiesByCountryId(ctry)
      }
      this.member.state_birth_hash = null
    },
    'member.birthday': function memberBirthday(value) {
      if (value) {
        this.validAge = this.getAge(value)
        if (this.validAge >= 18) {
          this.member.holder_name = null
        }
      } else {
        this.member.holder_name = null
      }
    },
  },
  mounted() {
    if (isObject(this.member.address) && hasKeyObject(this.member.address, 'country_hash')) {
      this.$nextTick(async () => {
        await this.getMemberAddressStates(this.member.address.country_hash)
      })
    }
    if (hasKeyObject(this.member, 'country_birth_hash')) {
      this.$nextTick(async () => {
        await this.getMemberBirthStates(this.member.country_birth_hash)
      })
    }
  },
  methods: {
    validatorFileSize,
    validatorFileType,
    hasKeyObject,
    isObject,
    checkPermission,
    ...mapActions({
      fetchStateByCountryId: 'fetchStateByCountryId',
      update: 'memberStore/update',
      destroy: 'memberStore/destroy',
      mediaFileUpload: 'mediaFile/upload',
      mediaFileDestroy: 'mediaFile/destroy',
    }),
    async onUpdateMember() {
      this.isLoadingSave = true
      this.member.cell_phone = this.resultsCellPhone.formattedNumber
      this.$refs.form2Update.validate().then(async isValid => {
        if (!isValid && this.member.is_complete_data === true) {
          this.showError(this.$t('fields-are-missing-to-complete'))
          this.isLoadingSave = false
          return
        }
        await this.update(this.member).then(response => {
          this.responseSuccessUpdate(response.data.message)
          this.isLoadingSave = false
          this.$emit('hidden-form')
        }).catch(error => {
          this.responseCatch(error)
        }).finally(() => {
          this.isLoadingSave = false
        })
      })
    },
    async onRemoveMember() {
      await this.sampleDeleteQuestion()
        .then(async result => {
          if (!result.isConfirmed) {
            return
          }
          await this.destroy(this.member.hash).then(response => {
            this.responseSuccessElimination(response.data.message)
            this.$router.push({ name: 'member' })
          })
        }).catch(error => {
          this.responseCatch(error)
        })
    },
    getAge(dateString) {
      const hoy = new Date()
      const fechaNacimiento = new Date(dateString)
      let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
      const diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
      if (
        diferenciaMeses < 0 || (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
      ) {
        edad -= 1
      }
      return edad
    },
    async getMemberBirthStates(pCountry) {
      await this.fetchStateByCountryId(pCountry).then(response => {
        this.memberBirthStates = response.data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    async getMemberAddressStates(pCountry) {
      await this.fetchStateByCountryId(pCountry).then(response => {
        this.memberAddressStates = response.data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    async fileValidation(fileData, typeField) {
      // const validSize = this.validatorFileSize(fileData)
      const validType = this.validatorFileType(fileData)

      // if (!validSize) {
      //   if (typeField === 'avatar_file') this.path_file_avatar = null
      //
      //   this.showError(`${this.$t('the-file-size-exceeds-the-allowed-size')}: 2MB`)
      //   return
      // }
      if (!validType) {
        if (typeField === 'avatar_file') this.path_file_avatar = null

        this.showError(`${this.$t('only-documents-in-PDF-and-image-format-are-allowed')}`)
        return
      }
      this.saveFileUpload(fileData, typeField)
    },
    async saveFileUpload(fileData, typeField) {
      const formData = new FormData()
      formData.append('file', fileData)
      formData.append('folder', 'members')
      formData.append('club_id', this.member.club_hash)
      await this.mediaFileUpload(formData).then(response => {
        const { data } = response.data
        // this.showSuccess(this.$t('generic.document'), this.$t('the-image-has-been-stored-correctly'))
        if (typeField === 'avatar_file') {
          const previousFileHash = this.member.avatar_file_media_file_hash
          if (previousFileHash != null) {
            this.mediaFileDestroy(previousFileHash)
              .then(() => {
              }).catch(error => {
                this.responseCatch(error)
              })
          }
          this.$set(this.member, 'avatar_file', data)
          this.$set(this.member, 'avatar_file_media_file_hash', data.hash)
        }
      }).catch(error => {
        this.responseCatch(error)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.repeater-form {
  overflow: visible;
  transition: .35s height;
}
.mb-30 {
  margin-bottom: 30px;
}
.btn-right {
  float: right !important;
}
.mt-18 {
  margin-top: 18px;
}
.btn-footer-modal {
    float: right
  }
@media (max-width: 450px) {
  .btn-footer-modal {
    float: none !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
</style>
