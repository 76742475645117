var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',[_c('validation-observer',{ref:"form1Update"},[_c('b-form',[_c('head-title-wizard',{attrs:{"section-player":_vm.sectionPlayer,"section-coaching-staff":_vm.sectionCoachingStaff,"option":_vm.option,"gender":_vm.genderSelect ? _vm.genderSelect : ''}}),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',[_c('div',{staticClass:"text-center"},[_c('upload-image',{attrs:{"tooltip-text":'generic.allowedFormat',"button-text":'generic.uploadPhoto',"avatar-icon":'icon-0-icons-dark-avatar',"image-size":'10rem',"apply-b-link":true,"disabled":_vm.member.club_hash == null,"path-file-avatar":_vm.member.avatar_file ? _vm.member.avatar_file.url_thumb : ''},on:{"intance-file-x":function($event){return _vm.fileValidation($event, 'avatar_file')}}}),_c('br')],1)])],1)],1)],1),_c('b-row',[_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])),expression:"checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"}],attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"club-country-id"}},[_vm._v(_vm._s(_vm.$t('generic.country'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"club-country-id","label":"name","options":_vm.countries,"reduce":function (country) { return country.id; },"placeholder":_vm.$t('generic.selectCountry'),"state":errors.length > 0 ? false : null},model:{value:(_vm.member.club.country_hash),callback:function ($$v) {_vm.$set(_vm.member.club, "country_hash", $$v)},expression:"member.club.country_hash"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])),expression:"checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"}],attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"club-state-id"}},[_vm._v(_vm._s(_vm.$t('generic.state'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"state"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"club-state-id","label":"name","options":_vm.clubStates,"reduce":function (city) { return city.hash; },"disabled":_vm.member.club.country_hash == null,"placeholder":_vm.$t('generic.selectState')},model:{value:(_vm.member.club.state_hash),callback:function ($$v) {_vm.$set(_vm.member.club, "state_hash", $$v)},expression:"member.club.state_hash"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL']))?_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"club-id"}},[_vm._v(_vm._s(_vm.$t('generic.club'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"sportsClubName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"club-id","label":"club_name","options":_vm.clubs,"reduce":function (club) { return club.hash; },"disabled":_vm.member.club.state_hash == null,"placeholder":_vm.$t('corporate.selectSportClub')},model:{value:(_vm.member.club_hash),callback:function ($$v) {_vm.$set(_vm.member, "club_hash", $$v)},expression:"member.club_hash"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,740174428)})],1)],1):_vm._e(),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('generic.team'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"team"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","placeholder":_vm.$t('generic.selectTeam'),"options":_vm.teams,"reduce":function (item) { return item.hash; },"disabled":_vm.member.club_hash == null},model:{value:(_vm.member.team_hash),callback:function ($$v) {_vm.$set(_vm.member, "team_hash", $$v)},expression:"member.team_hash"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.option === _vm.sectionPlayer)?[_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('member.form.label.category'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","placeholder":_vm.$t('member.form.placeholder.selectCategory'),"options":_vm.categories,"reduce":function (item) { return item.id; }},model:{value:(_vm.member.category_hash),callback:function ($$v) {_vm.$set(_vm.member, "category_hash", $$v)},expression:"member.category_hash"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2884200953)})],1)],1),(_vm.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL']))?_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}}):_vm._e()]:_vm._e(),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b',[_c('span',[_vm._v(_vm._s(_vm.$t('generic.contractTerm')))])])]),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}}),_c('b-col',{attrs:{"sm":"12","md":"3","lg":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('generic.initDate'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"initDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"form-control",attrs:{"locale":_vm.$i18n.locale,"placeholder":_vm.$t('member.form.placeholder.selectDate'),"state":errors.length > 0 ? false : null},model:{value:(_vm.member.validity_init_date),callback:function ($$v) {_vm.$set(_vm.member, "validity_init_date", $$v)},expression:"member.validity_init_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"3","lg":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('generic.endDate'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"endDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"form-control",attrs:{"locale":_vm.$i18n.locale,"min":_vm.member.validity_init_date,"placeholder":_vm.$t('member.form.placeholder.selectDate'),"state":errors.length > 0 ? false : null,"disabled":_vm.member.validity_init_date == null},model:{value:(_vm.member.validity_end_date),callback:function ($$v) {_vm.$set(_vm.member, "validity_end_date", $$v)},expression:"member.validity_end_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('upload-file',{attrs:{"title":_vm.$t('member.form.label.contract'),"folder":"members","disabled":_vm.member.club_hash == null,"club-id":_vm.member.club_hash,"file-title":_vm.member.file_contract ? _vm.member.file_contract.title : ''},on:{"uploaded-file":_vm.assignFileContract}})],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('member.form.label.registrationFederation'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"registrationFederation","rules":"required|max:254|alphaNumSpacesRegex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('member.form.placeholder.registryNumber')},model:{value:(_vm.member.registration_in_federation),callback:function ($$v) {_vm.$set(_vm.member, "registration_in_federation", $$v)},expression:"member.registration_in_federation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.option == _vm.sectionPlayer)?[_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('member.form.label.idFifa')))]),_c('validation-provider',{attrs:{"name":"idFifa","rules":"integer|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('member.form.placeholder.enterIdFifa')},model:{value:(_vm.member.id_fifa),callback:function ($$v) {_vm.$set(_vm.member, "id_fifa", $$v)},expression:"member.id_fifa"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3450520614)})],1)],1)]:_vm._e(),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('member.form.label.dateAdmission'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"dateAdmission","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"form-control",attrs:{"locale":_vm.$i18n.locale,"min":_vm.member.validity_init_date,"max":_vm.member.validity_end_date,"placeholder":_vm.$t('member.form.placeholder.selectDate'),"state":errors.length > 0 ? false : null},model:{value:(_vm.member.date_of_admission),callback:function ($$v) {_vm.$set(_vm.member, "date_of_admission", $$v)},expression:"member.date_of_admission"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.option == _vm.sectionPlayer)?[_c('b-col',{attrs:{"sm":"6","md":"3","lg":"3"}},[_c('jersey-number-modal',{attrs:{"jersey-numbers":_vm.jerseyNumbers,"jersey-number":_vm.member.jersey_number,"color-primary-btn":_vm.colorPrimaryBtn},on:{"update:jerseyNumber":function($event){return _vm.$set(_vm.member, "jersey_number", $event)},"update:jersey-number":function($event){return _vm.$set(_vm.member, "jersey_number", $event)},"handle-jersey-by-team-id":_vm.handleJerseyByTeamId}})],1),_c('div',{staticClass:"w-100"}),_c('b-col',{attrs:{"sm":"6","md":"3","lg":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('member.form.label.status'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","placeholder":_vm.$t('member.form.placeholder.selectStatus'),"options":_vm.playerStatuses,"state":errors.length > 0 ? false : null,"reduce":function (item) { return item; }},model:{value:(_vm.member.player_status),callback:function ($$v) {_vm.$set(_vm.member, "player_status", $$v)},expression:"member.player_status"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3461028361)})],1)],1),(_vm.memberPlayerStatusKey == 'LOANED_BY')?_c('b-col',{attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('club-cedente'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"sportsClubName","rules":"required|max:254|alphaNumSpacesRegex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('enter-sport-club-name')},model:{value:(_vm.member.ceding_club),callback:function ($$v) {_vm.$set(_vm.member, "ceding_club", $$v)},expression:"member.ceding_club"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,515073263)})],1)],1):_vm._e(),(_vm.memberPlayerStatusKey == 'LOANED_TO')?_c('b-col',{attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('transferee-club'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"sportsClubName","rules":"required|max:254|alphaNumSpacesRegex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('enter-sport-club-name')},model:{value:(_vm.member.ceding_club),callback:function ($$v) {_vm.$set(_vm.member, "ceding_club", $$v)},expression:"member.ceding_club"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,515073263)})],1)],1):_vm._e()]:_vm._e(),(_vm.option == _vm.sectionCoachingStaff)?[_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('member.form.label.gender'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"gender"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","placeholder":_vm.$t('member.form.placeholder.selectGender'),"options":_vm.genders,"reduce":function (item) { return item.id; },"state":errors.length > 0 ? false : null},model:{value:(_vm.member.gender_hash),callback:function ($$v) {_vm.$set(_vm.member, "gender_hash", $$v)},expression:"member.gender_hash"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1918710902)})],1)],1),_c('b-col',{attrs:{"sm":"10","md":"4","lg":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"name"}},[_vm._v(" "+_vm._s(_vm.$t("generic.jobPosition"))+" "),(!_vm.applyOther)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('validation-provider',{attrs:{"rules":_vm.applyOther ? '' : 'required',"name":"jobPosition"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":_vm.staffJobPositions,"reduce":function (job) { return job.id; },"disabled":_vm.applyOther || _vm.member.gender_hash == null,"placeholder":_vm.$t('generic.jobPosition')},model:{value:(_vm.member.staff_job_position_hash),callback:function ($$v) {_vm.$set(_vm.member, "staff_job_position_hash", $$v)},expression:"member.staff_job_position_hash"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3956844095)})],1)],1),_c('b-col',{attrs:{"sm":"2","md":"2","lg":"2"}},[_c('b-button',{staticClass:"mt-2",attrs:{"variant":_vm.applyOther ? 'secondary' : 'primary',"pill":""},on:{"click":function($event){_vm.applyOther = !_vm.applyOther}}},[(_vm.applyOther)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t('generic.addJobPosition')))])])],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}}),(_vm.applyOther)?_c('b-col',{attrs:{"sm":"10","md":"4","lg":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"name"}},[_vm._v(" "+_vm._s(_vm.$t("generic.jobPosition"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|max:254|alphaNumSpacesRegex","name":"jobPosition"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('generic.enterJob')},model:{value:(_vm.member.another_job),callback:function ($$v) {_vm.$set(_vm.member, "another_job", $$v)},expression:"member.another_job"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4007037503)})],1)],1):_vm._e()]:_vm._e()],2)],1)],1),_c('div',{staticClass:"btn-footer-modal"},[_c('b-button',{staticStyle:{"margin-left":"17px"},attrs:{"variant":"secondary","pill":""},on:{"click":function($event){return _vm.$emit('hidden-form')}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),_c('overlay-button',{attrs:{"loading":_vm.isLoadingSave}},[_c('b-button',{staticStyle:{"margin-left":"17px"},style:(_vm.colorPrimaryBtn),attrs:{"variant":"primary","pill":""},on:{"click":_vm.onUpdateMember}},[_vm._v(" "+_vm._s(_vm.$t("buttons.save"))+" ")])],1)],1),_c('div',{staticClass:"float-right"},[(_vm.checkDeleted)?_c('b-button',{staticClass:"d-none d-sm-none d-md-block",staticStyle:{"margin-left":"17px"},attrs:{"variant":"outline-secondary","pill":""},on:{"click":_vm.onRemoveMember}},[_c('i',{staticClass:"icon-0-icons-dark-trash-can mr-25"}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('buttons.delete')))])]):_vm._e()],1),(_vm.checkDeleted)?_c('b-button',{staticClass:"d-block d-sm-block d-md-none w-100 mt-2",attrs:{"variant":"outline-secondary","pill":""},on:{"click":_vm.onRemoveMember}},[_c('i',{staticClass:"icon-0-icons-dark-trash-can mr-25"}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('buttons.delete')))])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }