<template>
  <div>
    <b-list-group-item>
      <b-row>
        <b-col md="6">
          <strong class="float-left">{{ $t('generic.name') }}</strong>
        </b-col>
        <b-col md="6">
          <span
            v-if="agent.name"
            class="float-left"
          >{{ agent.name }}</span>
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item>
      <b-row>
        <b-col md="6">
          <strong class="float-left">{{ $t('licence-number') }}</strong>
        </b-col>
        <b-col md="6">
          <span
            v-if="agent.license_number"
            class="float-left"
          >{{ agent.license_number }}</span>
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item>
      <b-row>
        <b-col md="6">
          <strong class="float-left">{{ $t('activity-or-role-in-the-contract') }}</strong>
        </b-col>
        <b-col md="6">
          <span
            v-if="agent.role"
            class="float-left"
          >{{ agent.role }}</span>
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item>
      <b-row>
        <b-col md="6">
          <strong class="float-left">{{ $t('payment') }}</strong>
        </b-col>
        <b-col md="6">
          <span
            v-if="agent.payment_amount"
            class="float-left"
          >{{ agent.payment_amount }}</span>
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item>
      <b-row>
        <b-col md="6">
          <strong class="float-left">{{ $t('method-payment') }}</strong>
        </b-col>
        <b-col md="6">
          <span
            v-if="agent.method_payment"
            class="float-left"
          >{{ agent.method_payment }}</span>
        </b-col>
      </b-row>
    </b-list-group-item>

    <!-- Periodicidad Agent -->
    <b-list-group-item>
      <b-row>
        <b-col md="6">
          <strong class="float-left">{{ $t('periodicity') }}</strong>
        </b-col>
        <b-col md="6">
          <span
            v-if="agent.payment_period"
            class="float-left"
          >{{ agent.payment_period.name }}</span>
          <span
            v-if="hasKeyObject(agent.payment_period, 'key') && (agent.payment_period.key == 'OTHER')"
            class="float-left"
          >&nbsp;&nbsp;{{ agent.payment_period_other }}</span>
        </b-col>
      </b-row>
    </b-list-group-item>

    <!-- Vigencia -->
    <b-list-group-item>
      <b-row>
        <b-col md="6">
          <strong class="float-left">{{ $t('generic.validity') }}</strong>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col
              md="6"
            >
              <strong class="float-left">{{ $t('generic.initDate') }}</strong>
              <span
                v-if="agent.init_effective_date"
                class="float-left"
              >&nbsp;{{ agent.init_effective_date | formatDate }}</span>
            </b-col>
            <b-col
              md="6"
            >
              <strong class="float-left">{{ $t('generic.endDate') }}</strong>
              <span
                v-if="agent.end_effective_date"
                class="float-left"
              >&nbsp;{{ agent.end_effective_date | formatDate }}</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-list-group-item>

    <!-- Document -->
    <b-list-group-item>
      <b-row>
        <b-col md="6">
          <strong class="float-left">{{ $t('generic.document') }}</strong>
        </b-col>
        <b-col md="6">
          <span
            v-if="agent.file_document"
            class="float-left"
          >{{ agent.file_document.title }}</span>
          <div v-if="isObject(agent.file_document) && agent.file_document.mime_type != 'application/pdf'">
            <image-viewer
              class="float-left ml-1 cursor-pointer"
              :document-id="agent.path_file_document_media_file_hash"
              :image="agent.file_document.url_thumb"
              :height="'20'"
              :width="'20'"
              permission-show="VIEW_PLAYER_AGENT_DOCUMENT_FILE_MEMBERS"
              permission-download="DOWNLOAD_PLAYER_AGENT_DOCUMENT_FILE_MEMBERS"
            />
          </div>
          <div v-else>
            <ViewerDocumentModal
              class="float-left ml-1 cursor-pointer"
              :title="agent.file_document ? agent.file_document.title : ''"
              :document-id="agent.path_file_document_media_file_hash"
              :height="'20'"
              :width="'20'"
              permission-show="VIEW_PLAYER_AGENT_DOCUMENT_FILE_MEMBERS"
              permission-download="DOWNLOAD_PLAYER_AGENT_DOCUMENT_FILE_MEMBERS"
            />
            <!-- :url-thumb="agent.file_document ? agent.file_document.url_thumb : null" -->
          </div>
          <div v-if="agent.path_file_document_media_file_hash != null && checkPermission(['DOWNLOAD_PLAYER_AGENT_DOCUMENT_FILE_MEMBERS', 'ALLOW_ALL'])">
            <span
              class="float-right cursor-pointer"
              @click="handleDownloadFile(agent.path_file_document_media_file_hash, agent.file_document.title)"
            >
              <i class="icon-0-icons-dark-download btn-cursor" />
            </span>
          </div>
        </b-col>
      </b-row>
    </b-list-group-item>

    <!-- Representative -->
    <b-list-group-item>
      <b-row>
        <b-col md="6">
          <strong class="float-left">{{ $t('representative-or-agent') }}</strong>
        </b-col>
        <b-col md="6">
          <span
            v-if="agent.assign_representative_key != null && agent.assign_representative != null"
            class="float-left"
          >
            {{ agent.assign_representative.name }}
          </span>
        </b-col>
      </b-row>
    </b-list-group-item>
  </div>
</template>
<script>
import checkPermission from '@/auth/permissions'
import { hasKeyObject, downloadFile, isObject } from '@core/utils/utils'
import { mapActions } from 'vuex'
import ImageViewer from '@/views/member/ImageViewer.vue'
import ViewerDocumentModal from '@/views/member/ViewerDocumentModal.vue'

export default {
  components: {
    ImageViewer,
    ViewerDocumentModal,
  },
  props: {
    agent: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      //
    }
  },
  methods: {
    checkPermission,
    hasKeyObject,
    downloadFile,
    isObject,
    ...mapActions({
      mediaFileShow: 'mediaFile/show',
    }),
    async handleDownloadFile(fileHash, title) {
      this.isLoading = true
      await this.mediaFileShow(fileHash).then(response => {
        const { data: dataDocument } = response
        this.downloadFile(dataDocument, title)
      }).catch(error => {
        this.isLoading = false
        this.responseCatch(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>
